import React from 'react';
import ReactDOM from 'react-dom';

import Main from './components/modules/Main';
import Error from "./components/Error";
import Help from "./components/Help";
import ProgUser from "./components/modules/admin/ProgUser";
import Audit from "./components/modules/audit/Audit";
import RecoveryPassword from "./components/lib/RecoveryPassword";
import ServicesUsers from "./components/modules/admin/ServicesUsers";
import TemplateNotification from "./components/modules/notification/TemplateNotification";

import { MSG_PAGENOTFOUND, DEFAULT_DATE_FORMAT } from "./components/lib/Const";

import './resources/css/theme.less';
import './resources/css/index.css';

import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import moment from 'moment';
import 'moment/locale/ru';

import { Route } from 'react-router';
import { BrowserRouter, Switch } from "react-router-dom";
import TypeDevices from './components/modules/devices/TypeDevices';
import Device from './components/modules/devices/Device';
import DeviceIssues from "./components/modules/devices/DeviceIssues";


document.documentElement.lang = 'ru';
moment.locale('ru');
moment().format(DEFAULT_DATE_FORMAT);

const validateMessages = {
    required: "Поле не может быть пустым",// eslint-disable-line
    string: {
        max: "Длина '${label}' не может быть больше ${max}"// eslint-disable-line
    },
    types:{
        email:"Неверный формат ввода для электронной почты"
    }
};
console.log("environment=", process.env);

ReactDOM.render(
    <ConfigProvider locale={ruRU} form={{ validateMessages }}>
        <BrowserRouter>
            <Switch>
                <Route exact path='/'><Main /></Route>
                {/* Контур Администрирование */}
                <Route exact path='/proguser'><ProgUser /></Route>
                <Route exact path='/servicesusers'><ServicesUsers /></Route>
                <Route exact path='/typedevices'><TypeDevices/></Route>
                <Route exact path='/typedevices/:id'><TypeDevices/></Route>
                <Route exact path='/device'><Device/></Route>
                <Route exact path='/device/:id/issues'><DeviceIssues/></Route>
                {/* Аудит */}re
                <Route exact path='/audit'><Audit /></Route>
                {/* Тестовый контур */}
                <Route exact path='/templatenotification'><TemplateNotification /></Route>

                <Route path='/help'><Help /></Route>
                <Route path='/recovery/:key'><RecoveryPassword /></Route>
                <Route><Error text={MSG_PAGENOTFOUND} helpId="/help/pagenotfound" /></Route>
            </Switch>
        </BrowserRouter>
    </ConfigProvider>
    , document.getElementById('root')
);