import React from 'react';
import { Form } from 'antd';
import DataTable,{ColumnTypes} from "../../lib/DataTable";
import App from '../../App';
import ModuleHeader from "../../lib/ModuleHeader";
import { FilterPanelExt, Primary } from "../../lib/FilterPanelExt";
import { FilterButton } from '../../lib/FilterButton';
import { withRouter } from "react-router";
import { DEFAULT_TABLE_CONTEXT, EVENT } from "../../lib/Const";
import { RedoOutlined } from '@ant-design/icons';
import { drawDateAndTimeIncSec } from "../../lib/Utils";
import EditForm from "../../lib/EditForm";
import { CONTOUR_ADMIN, MODULE_AUDIT } from "../../lib/ModuleConst"
import AuditForm from './AuditForm';
import { DateInputRange } from '../../lib/DateInput';
import DataLookup from '../../lib/DataLookup';
import moment from 'moment';
import 'moment-duration-format';
import AuditEventSelect from './AuditEventSelect';
import { responsiveMobileColumn, isMobile } from '../../lib/Responsive';
import { QUERY_GET_LIST_LOG, QUERY_GET_LOG } from '../../lib/Query';
import { PanelButton } from '../../lib/PanelButton';


const MOD_TITLE = "Журнал логов";
const MODE_HELP_ID = "/help/audit";
const CONTOUR = CONTOUR_ADMIN;
const MODULE = MODULE_AUDIT;

// URI для использования формой со списком (текущей) и формой добавления/изменения
const URI_FOR_GET_LIST = QUERY_GET_LIST_LOG;
const URI_FOR_GET_ONE = QUERY_GET_LOG;

// позиция в меню
// в subsystem - key верхнего меню
const MNU_SUBSYSTEM = CONTOUR.name;
// в menu - key бокового главного
const MNU_MENU = MODULE.name;
const NAME_MENU = "Аудит";
// в submenu - key бокового подменю (финальный пункт)
// его имя равно имени модуля
const MNU_SUMMENU = MODULE.name + ".sm1";
// const MNU_SUMMENU = "none";
// автоматическое обновление при монтировании компонента
const AUTO_REFRESH = true;


// колонки в таблице
const COLUMNS = [
    {
        title: 'Дата/Время',
        dataIndex: 'tsInSeconds',
        dataType:ColumnTypes.DATETIME,
        sorter: true,
        render:drawDateAndTimeIncSec,
        renderForFilter:(data)=>moment(data*1000).format("DD.MM.YYYY HH:mm"),
        defaultSortOrder: 'descend',
    },
    {
        title: 'Пользователь',
        dataIndex: 'ident.sysUser.login',
        getDataIndex:(record)=>(record["ident.sysUser.login"]?"ident.sysUser.login":"ident.devUser.login"),
        ellipsis: true,
        sorter: true,
        render:(data, record)=><div>{record["ident.sysUser.login"]?<span className="sys-user-login">{record["ident.sysUser.login"]}</span>:record["ident.devUser.login"]}</div>,
        responsive: responsiveMobileColumn()
    },
    {
        title: 'Тип',
        dataIndex: 'type.name',
        ellipsis: true,
        sorter: true,
        responsive: responsiveMobileColumn()
    },
    {
        title: 'Событие',
        dataIndex: 'note',
        ellipsis: true,
        sorter: true,
    },
    // В результат выводиться дата, тип, событие, но событие выводиться не правильно.
    {
        title: 'Результат',
        dataIndex: 'level',
        ellipsis: true,
        sorter: true,
    },
]


const transformerRecord = (r)=>{
    r["ident.sysUser.login"]=r.ident.sysUser?r.ident.sysUser.login:null;
    r["ident.devUser.login"]=r.ident.devUser?r.ident.devUser.login:null;
    r["type.name"]=r.type.name;
    r["type.id"]=r.type.id;
    r["level"]=EVENT[r.level]
    delete r.ident;
}    

const transformerData = (data)=>{
    data.forEach(r => 
        transformerRecord(r)
    );
    return data;
}


// Уникальный идентификатор формы редактировавания
const EDIT_FORM_ID =  "audit-frm";
// Форма для редактирования
const buildForm = (form) => {
    return <AuditForm form={form} initialValues={{}} />
}
// размер формы, -1 - по умолчанию, FORM_MAX_WIDTH - максимальная ширина
const FORM_WIDTH = isMobile() ? -1 : "50%";

// Создание компонент для фильтров
// key это уникальное имя фильтра, попадает в REST API
const buildFilters = () => {
    return <React.Fragment>
         <Primary>
            <DateInputRange key="ts_in_seconds" allowClear={false} />
            <span>Пользователь:</span>
            <DataLookup.Users key="ident.id" style={{ width: 320 }} allowClear={true} />
        </Primary>
        <span>События:</span>
        <AuditEventSelect key="typeId" style={{ width: 320 }}/>
    </React.Fragment>
}
// начальное значение фильтров
// если значение фильра не объект, а простое значение, 
// то имя свойства компонента принимается defaultValue
const initFilters = {
    ts_in_seconds: [moment().startOf('month'), moment().endOf('month')]
}

// дополнительные команды
// если меню нет, то и кнопки нет
/* 
const buildMenuCommand = (config, handleMenuClick) => {
    return <Menu onClick={handleMenuClick}>
        {buildPrintMenu(MODULE.name, config)}
    </Menu>
};

// обрабочик меню
const buildMenuHandler = (config) => {
    return (ev) => {
    }
}
 */

// меню записи
/*
const recordMenu = (config, record) => (
    <React.Fragment>
        {buildEntityPrintMenu(ENTITY, record, config)}
    </React.Fragment>
)
*/

//===============================================================================
// Основной функциональный компонент
//===============================================================================
/**
 * Таблица передает на сервер post-запрос в теле которого 
 * pagination - информация о странице
 * sort - сортировка
 * filters - фильтры (+ быстрые фильтры начинаются с quick.*)
 * search - строка полнотекстового поиска
 */
const Audit = (props) => {
    let [formVisible, setFormVisible] = React.useState(false);
    const [topLayer] = React.useState([]);
    let [editorContext] = React.useState({
        uriForGetOne: URI_FOR_GET_ONE
    });
    const [tableInterface] = React.useState(Object.assign({}, DEFAULT_TABLE_CONTEXT));
    const [form] = Form.useForm();
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);
    const [updateRecords, setUpdateRecords] = React.useState([]);

/*     
    const menuCommand = buildMenuCommand({ form: form, forceUpdateModule: forceUpdate }, buildMenuHandler({
        topLayer,
        setTopLayer,
        form,
        tableInterface,
        destroyDialog: (dlgId) => {
            setTopLayer([...topLayer.filter(c => c.props.id != dlgId)]);
        }
    }));
 */

    const setFilters = React.useCallback((config) => {
        tableInterface.requestParams.filters = config;
        tableInterface.refreshData();
    }, [tableInterface])


    const callForm = React.useCallback((id) => {
        editorContext.id = id;
        setFormVisible(true);
    }, [editorContext])

    // тут формируются кнопки
    const buttons = [
        // <Button key="refresh" onClick={() => tableInterface.refreshData()}>{BUTTON_REFRESH_LABEL}</Button>,
        <PanelButton title="Обновить" key="refresh" onClick={() => tableInterface.refreshData()} icon={<RedoOutlined />} />
        ];
/*    
    if (menuCommand) {
        buttons.push(<Dropdown.Button key="more"
            className="more-dropbutton"
            trigger="click"
            overlay={menuCommand} icon={<MoreOutlined />} />);
    }
*/    
    if (isMobile()) {
        const filters = buildFilters();
        buttons.push(<FilterButton key="filter" filters={filters}
            onChange={(fc) => setFilters(fc)}
            initValues={initFilters} />);
    }

    return (
        <App subsystem={MNU_SUBSYSTEM} menu={MNU_MENU} submenu={MNU_SUMMENU}
            breadcrumb={[{ label: NAME_MENU }, { label: MOD_TITLE }]}
            afterLogin={forceUpdate}
            buttons={buttons}
            helpId={MODE_HELP_ID}>
            <ModuleHeader
                title={MOD_TITLE}
                onSearch={value => {
                    tableInterface.requestParams.search = value ? value : undefined;
                    tableInterface.refreshData();
                }}
                buttons={buttons}
            />
            <FilterPanelExt onChange={(fc) => setFilters(fc)} initValues={initFilters}>
                {buildFilters()}
            </FilterPanelExt>
            <DataTable className="mod-main-table"
                uri={{
                    forSelect: URI_FOR_GET_LIST
                }}
                columns={COLUMNS}
                exportNameFile='audit'
                defaultFilters={initFilters}
                autoRefresh={AUTO_REFRESH}
                editCallBack={(record) => callForm(record.id)}
                interface={tableInterface}
                onSelectedChange={() => forceUpdate()}
                onAfterRefresh={() => setUpdateRecords([])}
                updateRecords={updateRecords}
                /*
                recordMenu={(record) => recordMenu({
                    topLayer,
                    setTopLayer,
                    form,
                    tableInterface,
                    idName: ENTITY.charAt(0).toLowerCase() + ENTITY.slice(1) + "Id",
                    'destroyDialog': (dlgId) => {
                        // нужно через timeout так как после вызова destroyDialog следуют обращения к state
                        setTimeout(() => { setTopLayer([...topLayer.filter(c => c.props.id != dlgId)]) }, 100)
                    }
                }, record)}
                */
                transformerData={transformerData}
            />
            <EditForm
                id={EDIT_FORM_ID}
                title={"Запись лога"}
                visible={formVisible}
                form={form}
                width={FORM_WIDTH}
                editorContext={editorContext}
                afterSave={(response) => {
                    setFormVisible(false);
                }}
                afterCancel={() => {
                    setFormVisible(false);
                }}
                idName="id"
            >
                {buildForm(form)}
            </EditForm>
            {topLayer.map(item => item)}
        </App>
    )
}
export default withRouter(Audit);