import React from 'react';
import App from '../App';
import ModuleHeader from "../lib/ModuleHeader";
import requestToAPI from "../lib/Request";
import { LoginContent } from "../lib/LoginForm";
import { SetPasswordContent } from "../lib/SetPasswordForm";
import { ForgetPassword } from "../lib/ForgetPassword";
import { CONTOUR_ADMIN } from '../lib/ModuleConst';
import logoFull from '../../resources/images/logo-full.svg';
const MOD_TITLE = "Добро пожаловать в систему E-MODE";
const MODE_HELP_ID = "help/main"

function SignupContent(props) {
    const [controlCase, setControlCase] = React.useState("");
    const [userName, setUserName] = React.useState("");


    switch (controlCase) {
        case "tempPass":
            return <SetPasswordContent cb={props.cb} userName={userName}/>;
        case "forgetPass":
            return <ForgetPassword/>
        default:
            return <LoginContent cb={props.cb} setControlCase={setControlCase} setUserName={setUserName}/>
    }
}

const Main = (props) => {
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);
    // проверяем наличие сохраненного токен
    let saveToken = sessionStorage.getItem("authToken") ?? localStorage.getItem("authToken");

    return requestToAPI.authToken || saveToken ? (
        <App subsystem={CONTOUR_ADMIN.name} menu={'none'} submenu={'none'}
            helpId={MODE_HELP_ID}
            defaultCollapsed={false}
            afterLogin={forceUpdate}
            afterLogout={forceUpdate}
        >
            <ModuleHeader title={MOD_TITLE} search={false} />
            {/* <p>
                Общая информация о продукте, телефоны СТП и прочее...
            </p> */}
        </App>
    ) : (
        <React.Fragment>
            <div className="header">
                <div className="logotype">
                    <img src={logoFull} alt="Logotype"/>
                </div>
                <hr className="gradient"/>
                <div className="app-version">
                    Версия {process.env.REACT_APP_VERSION}
                </div>
            </div>
            <div className="wrap-3">
                <div className="wrap-2">
                    <div className="wrap-1">
                        <SignupContent cb={forceUpdate}/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Main;
