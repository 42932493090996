export const QUERY_GET_TOKEN = `
    mutation sysUserAuth($userLogin: String!, $password: String!) {
        sysUserPasswdAuth(login: $userLogin, password: $password) {
            id
            userLogin
            userName
            authToken
        }
    }
`
export const MUT_REVOKE_TOKEN = `
    mutation {
        revokeAuthToken {
            code
        }
    }  
`

export const QUERY_RENEW_TOKEN = `
    mutation authTokenRenew {
    authTokenRenew {
      authToken
    }
  }
`


export const QUERY_GET_DEVUSER = `
    query DevUser($id: bigint!) {
        record: devUserByPk(id: $id) {
            login
            id
            registered_in_seconds
            patronymic
            lastName
            firstName
            ident {
                blocked
                devUser {
                    email
                    phone
                }
            }
        }
    }
`

export const QUERY_GET_LIST_DEVUSER = `
    query DevUser($order_by: [DevUser_order_by!] = {}, $limit: Int = 10, $offset: Int = 0, $where: DevUser_bool_exp = {}) {
        records:devUser(order_by: $order_by, limit: $limit, offset: $offset, where: $where) {
            firstName
            id
            lastName
            login
            patronymic
            registered_in_seconds
            ident {
                blocked
                devUser {
                    email
                    phone
                }
            }
        }
        recordCount:devUserAggregate(where: $where) {
            aggregate {
                count
            }        
        }
    }
`

export const QUERY_GET_LIST_LOG = `
    query LogEvent($order_by: [LogEvent_order_by!] = {}, $limit: Int = 10, $offset: Int = 0, $where: LogEvent_bool_exp = {}) {
        records:logEvent(order_by: $order_by, limit: $limit, offset: $offset, where: $where) {
            id
            note
            level
            type {
                id
                name
            }
            tsInSeconds
            ident {
                id
                sysUser {
                   login
                }
                devUser {
                   login
                }
              }
        }
        recordCount:logEventAggregate(where: $where) {
            aggregate {
                count
            }        
        }
        
    }
`

export const QUERY_GET_LOG = `
    query LogEvent($id: bigint!) {
        record:logEventByPk(id: $id) {
            id
            level
            note
            type {
                name
                id
            }
            ts_in_seconds
            ident {
                sysUser {
                    login
                }
                devUser {
                    login
                }
            }
        }
    }
`

export const QUERY_GET_LIST_SYSUSER = `
    query SysUser($order_by: [SysUser_order_by!] = {}, $limit: Int = 10, $offset: Int = 0, $where: SysUser_bool_exp = {}) {
        records:sysUser(order_by: $order_by, limit: $limit, offset: $offset, where: $where) {
            firstName
            id
            lastName
            login
            patronymic
            registered_in_seconds
            ident {
              blocked
              sysUser {
                email
                phone
              }
            }
        }
        recordCount:sysUserAggregate(where: $where) {
            aggregate {
              count
            }        
        }
    }
`

export const QUERY_GET_SYSUSER =  `
    query SysUser($id: bigint!) {
        record:sysUserByPk(id: $id) {
            login
            registered_in_seconds
            patronymic
            lastName
            id
            firstName
            ident {
                blocked
                sysUser {
                    email
                    phone
                  }
                }
        }
    }

`

export const MUT_INS_SYSUSER =  `
    mutation InsertSysUser($login: String!, $firstName: String, $lastName: String, $patronymic: String, $ident_sysUser_email: String, $ident_sysUser_phone: String) {
        record:insertSysUserOne(object: {login: $login, firstName: $firstName, lastName: $lastName, patronymic: $patronymic, email: $ident_sysUser_email, phone: $ident_sysUser_phone}) {
            id
            login
            firstName
            lastName
            patronymic
            registered_in_seconds
            ident {
                blocked
                sysUser {
                    email
                    phone
                }
            }
        }
    }
`
//Внимание! Точки заменяются на подчеркивания, поэтому ident.sysUser.email становится ident_sysUser_email
export const MUT_UPD_SYSUSER =  `
    mutation updateSysUser($id:bigint!, $ident_sysUser_email: String, $firstName: String, $lastName: String, $patronymic: String, $ident_sysUser_phone: String, $ident_blocked: Boolean) {

        updateAccessIdentByPk(pk_columns:{id:$id},_set: {blocked: $ident_blocked}) {
            id
        }

        record: updateSysUserByPk(pk_columns:{id:$id}, _set: {email: $ident_sysUser_email, firstName: $firstName, lastName: $lastName, patronymic: $patronymic, phone: $ident_sysUser_phone}) {
            id
            firstName
            lastName
            login
            patronymic
            registered_in_seconds
            ident {
                blocked
                sysUser {
                    email
                    phone
                }
            }
        }
        
    }
    
`

export const MUT_UPD_SYSUSER_WITHOUT_BLOCK =  `
    mutation updateSysUser($id:bigint!, $ident_sysUser_email: String, $firstName: String, $lastName: String, $patronymic: String, $ident_sysUser_phone: String) {
        record: updateSysUserByPk(pk_columns:{id:$id}, _set: {email: $ident_sysUser_email, firstName: $firstName, lastName: $lastName, patronymic: $patronymic, phone: $ident_sysUser_phone}) {
            id
            firstName
            lastName
            login
            patronymic
            registered_in_seconds
            ident {
                blocked
                sysUser {
                    email
                    phone
                }
            }
        }
        
    }
    
`


export const MUT_SET_SYSUSER_PASSWORD = `
    mutation ($id: bigint!, $newPassword: String!) {
        setSysUserPasswd(password: $newPassword, userId: $id) {
            changed
        }
    }
`

export const MUT_INS_DEVUSER =  `
    mutation InserDevUser($login: String!, $firstName: String!, $lastName: String!, $patronymic: String, $ident_devUser_email: String, $ident_devUser_phone: String) {
        record: insertDevUserOne(object: {login: $login, firstName: $firstName, lastName: $lastName, patronymic: $patronymic, email: $ident_devUser_email, phone: $ident_devUser_phone}) {
            id
            login
            firstName
            lastName
            patronymic
            registeredInSeconds
            ident {
                devUser {
                    email
                    phone
                }
            }
        }
    }
`

export const MUT_UPD_DEVUSER = `
    mutation updateDevUser($id:bigint!, $ident_devUser_email: String, $firstName: String!, $lastName: String!, $patronymic: String, $ident_devUser_phone: String, $ident_blocked: Boolean) {
        updateAccessIdentByPk(pk_columns:{id: $id}, _set: {blocked: $ident_blocked}) {
            id
        }
        record: updateDevUserByPk(pk_columns:{id:$id}, _set: {email: $ident_devUser_email, firstName: $firstName, lastName: $lastName, patronymic: $patronymic, phone: $ident_devUser_phone}) {
            id
            firstName
            lastName
            login
            patronymic
            registered_in_seconds
            ident {
                blocked
                devUser {
                    email
                    phone
                }
            }
        }
    }
`

export const QUERY_GET_AUDIT_TYPE = `
    query{
        records:logEventType {
            id
            name
        }
    }
`

export const QUERY_GET_LIST_ALLUSER = `
    query ($search: String!) {
        records:accessIdent(where: {
            blocked: {_eq: false}, 
            _or: [{
                    sysUser: {allText: {_like: $search}},
                }, {
                    devUser:{allText:{_like:$search}}
                }
            ]
        }) 
        {
            id
            sysUser {
                login
                firstName
                lastName      
                email
            }
            devUser {
                login
                firstName
                lastName
                email
            }
        }
    }
`

export const QUERY_GET_LIST_MESSAGE = `
    query MessageTemplate($order_by: [MessageTemplate_order_by!] = {}, $limit: Int = 10, $offset: Int = 0, $where: MessageTemplate_bool_exp = {}) {
        records: messageTemplate(order_by: $order_by, limit: $limit, offset: $offset, where: $where) {
            id
            code
            title
            text
        }
        recordCount:messageTemplateAggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`

export const QUERY_GET_MESSAGE = `
    query($id: bigint!) {
        record: messageTemplateByPk(id: $id) {
            code
            id
            text
            title
        }
    }
`

export const MUT_INS_MESSAGE = `
    mutation InsertMessageTemplateOne($text: String!, $title: String!, $code: String!) {
        record:insertMessageTemplateOne(object: {text: $text, title: $title, code: $code}) {
            id
            text
            title
            code
        }
    }
`

export const MUT_UPD_MESSAGE = `
    mutation UpdateMessageTemplate($id: bigint!, $code: String!, $text: String!, $title: String!) {
        record: updateMessageTemplateByPk(pk_columns: {id: $id}, _set: {code: $code, text: $text, title: $title}) {
            id
            code
            text
            title
        }
    }
`

export const MUT_DEL_MESSAGE = `
    mutation ($ids:[bigint!]){
        delete:deleteMessageTemplate(where: {id: {_in: $ids}}) {
            returning {
                id
            }
        }
    }
`

export const QUERY_GET_LIST_TYPEDEVICE = `
    query($order_by: [DeviceModel_order_by!] = {}, $limit: Int = 10, $offset: Int = 0, $where: DeviceModel_bool_exp = {}) {
        records: deviceModel(order_by: $order_by, limit: $limit, offset: $offset, where: $where) {
            id
            name
            docs {
                file {
                  url
                  name
                  mimeType
                }
                type {
                    code
                }
            }
        }
        recordCount: deviceModelAggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`

export const QUERY_GET_ALL_TYPEDEVICES = `
    query ($where: DeviceModel_bool_exp = {}) {
        records: deviceModel(where: $where, order_by: {name: asc}) {
            id
            name
        }
    }
`


export const QUERY_GET_TYPEDEVICE = `
    query ($id: bigint!){
        record:deviceModelByPk(id: $id) {
            id
            name
            note
            metadata
            docs {
                id
                version
                note
                file {
                    id
                    url
                    name
                    mimeType
                    size
                }
                type {
                    id
                    code
                }
            }
            thingsBoardProfileId
        }

        widgets:deviceModelWidget(where: {modelId: {_eq: $id}}) {
            id
            modelId
            typeId
            type {
                note
            }  
        }

    }
`

export const MUT_UPD_TYPEDEVICE = `
    mutation ($id: bigint!, $name: String!, $metadata:jsonb, $note:String, $thingsBoardProfileId: uuid,
        $delfiles_ids: [bigint!] = [], $insfiles_obj: [DeviceDoc_insert_input!]=[],
        $inswidgets_obj: [updateDeviceModelWidget_insert_input!]! = [],
        $delwidgets_ids: [bigint!] = []) {
        
        # удаление документов, связанных с типов
        deletedoc:deleteDeviceDoc(where: {id: {_in: $delfiles_ids}}) {
            returning {
                id
            }
        }

        # вставка новых документов, связанных с типов
        insertdoc:insertDeviceDoc(objects: $insfiles_obj) {
            returning {
              fileId
              modelId
              typeId
            }
        }

        # удаление всех связанных виджетов
        deletewidget:deleteDeviceModelWidget(where: {id: {_in: $delwidgets_ids}}) {
            returning {
                id
            }
        }

        # вставка новых связанных виджетов
        insertwidget:insertDeviceModelWidget(objects: $inswidgets_obj) {
            returning {
              id
            }
        }

        record: updateDeviceModelByPk(pk_columns: {id: $id}, _set: {name: $name, metadata: $metadata, note: $note, thingsBoardProfileId: $thingsBoardProfileId}) {
            id
            name
            docs {
                modelId
                fileId
                typeId
                file {
                  url
                  name
                  mimeType
                }
                type {
                    code
                }
            }
        }

    }
`

export const MUT_INS_TYPEDEVICE = `
    mutation ($name: String!, $note:String, $metadata:jsonb, $thingsBoardProfileId: uuid) {
        record: insertDeviceModelOne(object: {name: $name, metadata: $metadata, note: $note, thingsBoardProfileId: $thingsBoardProfileId}) {
            id
            name
            docs {
                file {
                  url
                  name
                  mimeType
                }
                type {
                    code
                }
            }
        }
    }
`

export const MUT_DEL_TYPEDEVICE = `
    mutation ($ids:[bigint!]){
        delete: deleteDeviceModel(where: {id: {_in: $ids}}) {
            returning {
                id
            }
        }
    }
`

export const MUT_INS_TYPEDEVICE_DOC = `
    mutation ($insfiles_obj:[DeviceDoc_insert_input!]!) {
        records:insertDeviceDoc(objects: $insfiles_obj) {
            returning {
                id
                file {
                    mimeType
                    name
                    url
                }
                type {
                    code
                }
                modelId
                fileId
                typeId
            }
        }
    }
`


export const QUERY_GET_LIST_DEVICE = `
    query($order_by: [DeviceUnit_order_by!] = {}, $limit: Int = 10, $offset: Int = 0, $where: DeviceUnit_bool_exp = {}) {
        records: deviceUnit(order_by: $order_by, limit: $limit, offset: $offset, where: $where) {
            id
            number
            note
            manufacturedInSeconds
            owner {
                login
            }
            model {
                id
                name
            }
        }
        recordCount: deviceUnitAggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`

export const QUERY_GET_DEVICE = `
    query($id: bigint!) {
        record: deviceUnitByPk(id: $id) {
            id
            number
            note
            owner {
              login
            }
            manufacturedInSeconds
            model {
              name
              id
            }
            factorySettings
            issues {
              createdInSeconds
              description
              resolution
              resolvedInSeconds
              id
            }
            ownerships {
                start
                end
                user {
                  id
                  login
                }
            }
            identToken
            thingsBoardId
            thingsBoardName
        }
    }
`
export const MUT_INS_DEVICE = `
    mutation ($number:String, $note: String,$model_id: bigint!, $identToken:String, 
        $factorySettings: jsonb,$manufactured:timestamp, $thingsBoardId: uuid, $thingsBoardName: String) {
        record: insertDeviceUnitOne(object: {note: $note, modelId: $model_id, number: $number, identToken: $identToken, 
            factorySettings: $factorySettings, manufactured: $manufactured, thingsBoardId: $thingsBoardId, thingsBoardName: $thingsBoardName}) {
            id
            number
            note
            owner {
                login
            }
            manufacturedInSeconds
            model {
                name
                id
            }
            factorySettings
            issues {
                createdInSeconds
                description
                resolution
                resolvedInSeconds
                id
            }
            ownerships {
                start
                end
                user {
                  id
                  login
                }
            }
        }
    }
`

export const MUT_UPD_DEVICE = `
    mutation($id:bigint!,$number:String, $note: String,$model_id: bigint!, $identToken: String, 
        $factorySettings: jsonb, $thingsBoardId: uuid, $thingsBoardName: String) {

        record: updateDeviceUnitByPk(pk_columns: {id: $id}, _set: {note: $note, modelId: $model_id, number: $number, 
            identToken: $identToken, factorySettings: $factorySettings, thingsBoardId: $thingsBoardId, thingsBoardName: $thingsBoardName}) {

            id
            number
            note
            owner {
                login
            }
            manufacturedInSeconds
            model {
                name
                id
            }
            factorySettings
            issues {
                createdInSeconds
                description
                resolution
                resolvedInSeconds
                id
            }
            ownerships {
                start
                end
                user {
                  id
                  login
                }
            }
        }
    }
`


export const QUERY_GET_LIST_ISSUES = `
    query ($order_by: [DeviceUnitIssue_order_by!] = {}, $limit: Int = 10, $offset: Int = 0, $where: DeviceUnitIssue_bool_exp = {}) {
        records: deviceUnitIssue(order_by: $order_by, limit: $limit, offset: $offset, where: $where) {
            id
            resolution
            description
            createdInSeconds
            resolvedInSeconds
            unitId
        }
        recordCount: deviceUnitIssueAggregate(where: $where) {
            aggregate {
              count
            }
        }
    }

`

export const QUERY_GET_ISSUE = `
    query ($id: bigint!) {
        record: deviceUnitIssueByPk(id: $id) {
            id
            created
            createdInSeconds
            description
            resolution
            resolved
            resolvedInSeconds
            unitId
        }
    }

`

export const MUT_DEL_ISSUE = `
    mutation ($ids:[bigint!]) {
        delete: deleteDeviceUnitIssue(where: {id: {_in: $ids}}) {
            returning {
                id
            }
        }
    }
`

export const MUT_INS_ISSUE = `
    mutation ($created: timestamp, $description: String, $resolution: String, $resolved: timestamp, $unitId: bigint!) {
          record: insertDeviceUnitIssueOne(object: {description: $description, resolution: $resolution, created: $created, resolved: $resolved, unitId: $unitId}) {
                created
                createdInSeconds
                description
                resolution
                resolved
                resolvedInSeconds
                id
          }
    }
`

export const MUT_UPD_ISSUE = `
    mutation($id:bigint!, $created: timestamp, $description: String, $resolution: String, $resolved: timestamp) {
          record: updateDeviceUnitIssueByPk(pk_columns: {id: $id}, _set: {created: $created, description: $description, resolution: $resolution, resolved: $resolved}) {
            id
            description
            createdInSeconds
            created
            resolution
            resolved
            resolvedInSeconds
          }
    }

`

export const QUERY_GET_WIGET_TYPE = `
    query{
        records:widgetType(order_by: {note: asc}) {
            id
            code
            note
        }
    }  
`


export const QUERY_MUT_PROCESS_FIRMWARE = `
    mutation ($fileId: bigint!, $modelId: bigint!) {
        record: processFirmwarePacket(fileId: $fileId, modelId: $modelId) {
            fileIds
        }
    }
`

export const QUERY_DETACH_DEVICE =`
    mutation ($id: bigint!) {
      detachUnit(unitId: $id) {
        unitId
      }
    }
`
