import React from 'react';
import { Form, Input, Space, Tabs } from 'antd';
import moment from 'moment';
import {DEFAULT_DATETIME_FORMAT,EVENT} from "../../lib/Const"

const { TextArea } = Input;
const { TabPane } = Tabs;


const AuditForm = (props) => {
    let values = {...props.initialValues};
    const firstInputRef = React.useRef(null);

    React.useEffect(() => {
        setTimeout(() => {
            if (firstInputRef.current) {
                firstInputRef.current.focus({
                    cursor: 'end',
                })
            }
        }, 100);
    });



    if(Object.keys(values).length>0) {
        values.datetime=moment(values.datetime).format(DEFAULT_DATETIME_FORMAT);
        values.ident = values.ident.sysUser?values.ident.sysUser.login:values.ident.devUser.login;
        values.kindDisplay = values.type.name;
        values.inputJson = values.note;
        values.outputJson = EVENT[values.level];
    }

    return <Form
        form={props.form}
        layout="vertical"
        name="formEdizm"
        onFieldsChange={props.onFieldsChange}
        initialValues={values}
    >
         <Space  style={{ display: 'flex'}} align="baseline">
        <Form.Item
            name="datetime"
            label="Дата/Время"
            >
            <Input readOnly ref={firstInputRef}  />
        </Form.Item>
        <Form.Item
            name="ident"
            label="Пользователь"
            >
            <Input readOnly  />
        </Form.Item>
        </Space>
        <Form.Item
            name="kindDisplay"
            label="Тип"
            >
            <Input readOnly />
        </Form.Item>
        <Tabs defaultActiveKey="1">
            <TabPane tab="Событие" key="1">
                <TextArea readOnly rows={8} style={{ width: "100%" }} value={values.inputJson}/>
            </TabPane>
            <TabPane tab="Результат" key="2">
                <TextArea readOnly rows={8} style={{ width: "100%" }} value={values.outputJson}/>
            </TabPane>
        </Tabs>



    </Form>


}    

export default AuditForm;