import { Button, Tooltip } from "antd";
import React from "react";
import { isMobile } from "./Responsive";


export const PanelButton = React.forwardRef((props, ref) => {
    const {title, ...otherprops} = props
    if(isMobile()) {
        return <Button {...otherprops} ref={ref} >{props.children}</Button>
    } else {
        return <Tooltip title={title}><Button {...otherprops} ref={ref} >{props.children}</Button></Tooltip>
    }
})