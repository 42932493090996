import React from 'react';
import { Form, Input, Tabs, Space, Table, Button, notification } from 'antd';
import {DateInput} from "../../lib/DateInput";
import moment from 'moment';
import {DEFAULT_DATETIME_FORMAT, MSG_CONFIRM_MODIFY_SAVE, MSG_SAVE_ERROR} from "../../lib/Const";
import DataSelect from '../../lib/DataSelect';
import {drawDate, drawDateInSec,textAreaKeyDown} from "../../lib/Utils";
import { useHistory } from 'react-router-dom';
import { confirm } from "../../lib/Dialogs";
import {useMediaQuery} from "react-responsive";
import requestToAPI from "../../lib/Request";
import {getLoginButton} from "../../lib/LoginForm";
import {MUT_UPD_DEVICE} from "../../lib/Query";
const { TextArea } = Input;
const { TabPane } = Tabs;


const DeviceForm = (props) => {
    let val = {...props.initialValues};
    let values = {...val, factorySettings: val.factorySettings ? JSON.stringify(val.factorySettings, null, '  ') : null}
    const device_id = values.id
    const firstInputRef = React.useRef(null);
    const modeAdd = !props.initialValues.id;
    const isMobile = useMediaQuery({ maxWidth: 466 })
    const history = useHistory()


    React.useEffect(() => {
        setTimeout(() => {
            if (firstInputRef.current) {
                firstInputRef.current.focus({
                    cursor: 'end',
                })
            }
        }, 100);
    });

    if(modeAdd) {
        values.manufactured=moment();
    } else {
        values.manufactured=values.manufacturedInSeconds?moment(values.manufacturedInSeconds*1000):undefined;
    }

    // выполняется после валидацией, но до сохранения
    props.internalTrigger.onBeforeSave=(validvalues) => {
        // удаляем лишнее
        delete validvalues["owner.login"];

        if (validvalues.factorySettings !== "") {
            validvalues.factorySettings = JSON.parse(validvalues.factorySettings)
        } else {
            validvalues.factorySettings = null
        }

        if(!modeAdd) {
            delete validvalues.manufactured;
        } else {
            validvalues.manufactured = validvalues.manufactured.toDate().toISOString();
        }
    };

    const getListIssues = (id) => {

        if(props.internalTrigger.interface.isModified()) {
            confirm(MSG_CONFIRM_MODIFY_SAVE, () => {
                props.form.validateFields()
                    .then((values) => {
                        save(values)
                    }).catch((info) => {
                    console.log('Validate Failed:', info);
                });
            })
        }
        else{
            history.push(`/device/${id}/issues`)
        }
    }

    const convertSave = (values) => {
        let convertedValues = { ...values };
        // конвертируем имена ключей .->_
        Object.keys(convertedValues).forEach(k=>{
            if(k.indexOf(".")>=0) {
                convertedValues[k.replaceAll(".","_")]=convertedValues[k];
                delete convertedValues[k];
            }
        })
        return convertedValues;
    }

    const save = (values) => {
        // подготовка данных
        const valuesForSend = convertSave(values);
        delete valuesForSend['manufactured']
        delete valuesForSend['owner_login']
        const data = {...valuesForSend, id: device_id}
        // сохранение подготовленных данных
        let promise;
        promise = requestToAPI.post(MUT_UPD_DEVICE, data)
        promise
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                notification.error({
                    message: MSG_SAVE_ERROR,
                    description: error.message,
                    btn: getLoginButton(error.status, history)
                })
            })
        history.push(`/device/${device_id}/issues`)
    }

    const getTableIssues = ()=>{
        const columns=[
            {
                title: 'Дата инцидента',
                dataIndex: 'createdInSeconds',
                key: 'createdInSeconds',
                defaultSortOrder: 'descend',
                render:drawDateInSec,
                width: "101px",
            },
            {
                title: 'Описание',
                dataIndex: 'description',
                key: 'description',
                ellipsis: true,
            },
            {
                title: 'Дата решения',
                dataIndex: 'resolvedInSeconds',
                key: 'resolvedInSeconds',
                defaultSortOrder: 'descend',
                render:drawDateInSec,
                width: "101px",
            },
            {
                title: 'Решение',
                dataIndex: 'resolution',
                key: 'resolution',
                responsive: isMobile ? ['md'] : null
            },
        ];
        const dataSource = props.initialValues.issues;

        return <Table dataSource={dataSource.slice(0, 3)}
                      columns={columns}
                      title={() => <div>
                          <Button onClick={() => getListIssues(values.id)} className="btn-small" size={"small"}>
                              <span>Перейти к изменению списка инцидентов</span>
                          </Button>
                          <span className='number-incidents'>
                              {dataSource.length >= 3 ? `Всего: ${dataSource.length}` : ''}
                          </span>
                      </div>

                      }
                      rowKey="id"
                      pagination={false}
                      size={"small"}/>
    }

    const getTableHistory = ()=>{
        const columns=[
            {
                title: 'Дата начала',
                dataIndex: 'start',
                key: 'start',
                defaultSortOrder: 'descend',
                render: drawDate
            },
            {
                title: 'Дата Окончания',
                dataIndex: 'end',
                key: 'end',
                defaultSortOrder: 'descend',
                render: drawDate,
            },
            {
                title: 'Пользователь',
                dataIndex: 'user',
                key: 'user',
            },

        ];

        const dataSource = props.initialValues.ownerships.map( (obj, index) => {
            return {
                id: index,
                start: obj.start,
                end: obj.end,
                user: obj.user.login
            }
        })

        return <Table dataSource={dataSource}
                      columns={columns}
                      pagination={{pageSize: 3, showSizeChanger: false}}
                      size={"small"}
                      rowKey={"id"}/>
    }

    return<Form
        form={props.form}
        layout="vertical"
        name="formTypeDevice"
        onFieldsChange={props.onFieldsChange}
        initialValues={values}>
        <Form.Item
            label="Серийный номер"
            name='number'
            rules={[
                { required: true },
                { max: 255 }
            ]}>
            <Input ref={firstInputRef}/>
        </Form.Item>
        <Form.Item
                label="Описание производителя"
                name='note'
                rules={[
                    { max: 255 }
                ]}>
                <Input/>
        </Form.Item>
        <Space  style={{ display: 'flex'}} align="baseline">
            <Form.Item
                label="Произведен"
                name="manufactured"
                rules={[
                ]}>
                <DateInput disabled={!modeAdd} allowClear={false} 
                           format={DEFAULT_DATETIME_FORMAT}
                           style={{width:160}}/>
            </Form.Item>
            <Form.Item
                label="Владелец"
                name='owner.login'>
                <Input readOnly/>
            </Form.Item>
        </Space>
        <Space style={{ display: 'flex'}} align="baseline">
            <Form.Item
                label="Тип прибора"
                name='model.id'
                rules={[
                    { required: true }
                ]}
                style={isMobile ? {width:160} : {width:250}}

            >
                <DataSelect.TypeDeviceSelect
                    displayValue={values["model.name"]}/>
            </Form.Item>
            <Form.Item
                label={!isMobile ? "Токен идентификации" : "Токен идент."}
                name='identToken'
                rules={[
                    { max: 255 }
                ]}
            style={{textOverflow: 'ellipsis'}}>
                <Input />
            </Form.Item>
        </Space>
        <Space  style={{ display: 'flex'}} align="baseline">
            <Form.Item
                label="Имя в ThingsBoard"
                name='thingsBoardName'
                rules={[
                    { max: 255 }
                ]}
                style={isMobile ? {width:160} : {width:250}}>
                <Input />
            </Form.Item>
            <Form.Item
                label="Ид. в ThingsBoard"
                name='thingsBoardId'
                rules={[
                    {
                        message: "Введенное значение не соответсвует типу UUID",
                        pattern: '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
                    }
                ]}>
                <Input />
            </Form.Item>
        </Space>


        <Tabs defaultActiveKey="1" style={{marginBottom: -20}}>
            <TabPane tab={"Заводские настройки"} key={1} style={{height: 220}}>
                <Form.Item
                    name='factorySettings'
                    rules={[
                    ]}>
                    <TextArea 
                            style={{ height: 190 }} onKeyDown={textAreaKeyDown}/>
                </Form.Item>
            </TabPane>
            {
                modeAdd?"":
                <>
                    <TabPane tab={"Инциденты"} key={2} style={{height: 220}}>
                        {getTableIssues()}
                    </TabPane>    
                    <TabPane tab={"Перемещения между пользователями"} key={3} style={{height: 220}}>
                        {getTableHistory()}
                    </TabPane>    
                </>
            }
        </Tabs>    

    </Form>


}

export default DeviceForm;