import React from 'react';
import { Form, Input} from 'antd';
import { FORM_ITEMS_LAYOUT } from "../../lib/Const";

export const SetPasswordServicesUsersForm = (props) => {
    const [firstInputRef] = React.useState(React.useRef(null));

        React.useEffect(() => {
        setTimeout(() => {
            if (firstInputRef.current) {
                firstInputRef.current.focus({
                    cursor: 'end',
                })
            }
        }, 100);
    });

    return <Form
        {...FORM_ITEMS_LAYOUT}
        form={props.form}
        layout="horizontal"
        name="formSetPassword"
        onFieldsChange={props.onFieldsChange}
        initialValues={{}}
        >
        <Form.Item
            name='id'
            hidden={true}>
            <Input />
        </Form.Item>
        <Form.Item
            name='newPassword'
            hasFeedback
            rules={[
                { required: true }
            ]}>
            <Input.Password placeholder={'Новый пароль'} ref={firstInputRef} autoComplete={"new-password"} />
        </Form.Item>
        <Form.Item
            name='confirmPassword'
            dependencies={['newPassword']}
            hasFeedback
            rules={[
                { required: true },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error('Значения введенных паролей не совпадают'));
                    },
                  })
            ]}>
            <Input.Password placeholder={'Подтверждение пароля'} autoComplete={"new-password"}/>
        </Form.Item>
    </Form>
}
