import React from 'react';
import { Form } from 'antd';
import DataTable, { ColumnTypes } from "../../lib/DataTable";
import App from '../../App';
import ModuleHeader from "../../lib/ModuleHeader";
import { FilterPanelExt } from "../../lib/FilterPanelExt";
import { FilterButton } from '../../lib/FilterButton';
import { withRouter } from "react-router";
import { DEFAULT_TABLE_CONTEXT } from "../../lib/Const";
import { drawBoolIcon, drawDateAndTimeIncSec, drawBoolAsText } from "../../lib/Utils";
import EditForm from "../../lib/EditForm";
import ProgUsersForm from "./ProgUserForm";
import { CONTOUR_ADMIN, MODULE_CREDENTIAL, MODULE_TYPEOFUSERS } from "../../lib/ModuleConst";
import { responsiveMobileColumn, isMobile } from '../../lib/Responsive';
import {
    PlusOutlined, RedoOutlined
} from '@ant-design/icons';
import { QUERY_GET_LIST_DEVUSER, QUERY_GET_DEVUSER, MUT_INS_DEVUSER, MUT_UPD_DEVUSER } from "./../../lib/Query";
import moment from 'moment';
import { PanelButton } from '../../lib/PanelButton';

const ENTITY_TITLE = "Владельцы";
const MODULE_TITLE = "Типы пользователей"
const MODE_HELP_ID = "/help/proguser";
const CONTOUR = CONTOUR_ADMIN;
const MODULE = MODULE_CREDENTIAL;

// URI для использования формой со списком (текущей) и формой добавления/изменения
const URI_FOR_GET_LIST = QUERY_GET_LIST_DEVUSER;
const URI_FOR_GET_ONE = QUERY_GET_DEVUSER;
const URI_FOR_GET_DEFAULT = undefined; // когда необходимо вызвать метод с сервера для заполнения данных по умолчанию

const URI_FOR_INSERT = MUT_INS_DEVUSER;
const URI_FOR_UPDATE = MUT_UPD_DEVUSER;
const URI_FOR_DELETE = undefined;

// позиция в меню   
// в subsystem - key верхнего меню
const MNU_SUBSYSTEM = CONTOUR.name;
// в menu - key бокового главного
const MNU_MENU = [MODULE_TYPEOFUSERS.name, MODULE.name];
const NAME_MENU = "Справочники";
// в submenu - key бокового подменю (финальный пункт)
// его имя равно имени модуля
const MNU_SUMMENU = MODULE_TYPEOFUSERS.name + ".sm1";
// автоматическое обновление при монтировании компонента
const AUTO_REFRESH = true;

// колонки в таблице
const COLUMNS = [
    {
        title: 'Блокирован',
        dataIndex: 'ident.blocked',
        sorter: true,
        width: "110px",
        render: drawBoolIcon,
        renderForFilter: drawBoolAsText,
        responsive: responsiveMobileColumn()
    },
    {
        title: 'Дата регистрации',
        dataIndex: 'registered_in_seconds',
        dataType: ColumnTypes.DATETIME,
        sorter: true,
        ellipsis: true,
        render: drawDateAndTimeIncSec,
        renderForFilter: (data) => moment(data * 1000).format("DD.MM.YYYY HH:mm"),
        defaultSortOrder: 'ascend',
    },
    {
        title: 'Логин',
        dataIndex: 'login',
        sorter: true,
        ellipsis: true,
        responsive: responsiveMobileColumn()
    },
    {
        title: 'E-mail',
        dataIndex: 'ident.devUser.email',
        sorter: true,
        ellipsis: true,
        responsive: responsiveMobileColumn()
    },
]

// преобразование пакета данных в плоскую структуру
const transformerRecord = (r) => {
    r["ident.blocked"] = r.ident.blocked;
    r["ident.devUser.email"] = r.ident.devUser.email;
    r["ident.devUser.phone"] = r.ident.devUser.phone;
    delete r.ident;
}

const transformerData = (data) => {
    data.forEach(r =>
        transformerRecord(r)
    );
    return data;
}

// Уникальный идентификатор формы редактировавания
const EDIT_FORM_ID = "proguser-frm";
// Форма для редактирования
const buildForm = (form) => {
    return <ProgUsersForm form={form} initialValues={{}} />
}
// размер формы, -1 - по умолчанию, FORM_MAX_WIDTH - максимальная ширина
const FORM_WIDTH = -1;

// Создание компонент для фильтров
// key это уникальное имя фильтра, попадает в REST API
const buildFilters = () => {
    return <React.Fragment>
        {/*
        <Primary>
            <span>Фильтры</span>
        </Primary>
        <Select key="statusDisplay" style={{ width: 160}} allowClear={true}  placeholder="Статус">
                {statusTypeList.map(value => <Option key={value.id} value={value.id}>{value.title}</Option>)}
            </Select>
        <DateInputRange key="dateRange" allowClear={false} />
        <DataLookup.ProgUser key="servicesUsersName" style={{ width: 240 }} allowClear={true} placeholder="Логин"/>
        <DataLookup.ProgUser key="servicesUserschannelAddress" style={{ width: 240 }} allowClear={true}  placeholder="E-mail" />
        <DataLookup.ProgUser key="servicesUsersphone" style={{ width: 240 }} allowClear={true}  placeholder="Телефон" />
        */}
    </React.Fragment>
}
// начальное значение фильтров
// если значение фильра не объект, а простое значение,
// то значение имени свойства компонента принимается как defaultValue компонента
const initFilters = {
}

// дополнительные команды
// если меню нет, то и кнопки нет

/*
const buildMenuCommand = (config, handleMenuClick) => {
    return <Menu onClick={handleMenuClick}>
        {buildPrintMenu(MODULE.name, config)}
    </Menu>
};
 
// обрабочик меню
const buildMenuHandler = (config) => {
    return (ev) => {
        console.log('click', ev);
    }
}
*/

// меню для записи
// const recordMenu = (config, record) => (
//     <React.Fragment>
//         {buildEntityPrintMenu(ENTITY, record, config)}
//         <Menu.Divider />
//         <Menu.Item key="manageAccessRole" icon={<UsergroupAddOutlined />} onClick={(ev) => manageAccessRole(ev, config, record)}>Роли</Menu.Item>
//         <Menu.Item key="setPasswordProgUser" onClick={(ev) => setPasswordProgUser(ev, config, record)}>Установить пароль</Menu.Item>
//     </React.Fragment>
// )

//===============================================================================
// Основной функциональный компонент
//===============================================================================
/**
 * Таблица передает на сервер post-запрос в теле которого
 * pagination - информация о странице
 * sort - сортировка
 * filters - фильтры (+ быстрые фильтры начинаются с quick.*)
 * search - строка полнотекстового поиска
 */

const ProgUsers = (props) => {
    let [formVisible, setFormVisible] = React.useState(false);
    const [topLayer] = React.useState([]);

    let [editorContext] = React.useState({
        uriForGetOne: URI_FOR_GET_ONE,
        uriForGetDefault: URI_FOR_GET_DEFAULT,
        uriForInsert: URI_FOR_INSERT,
        uriForUpdate: URI_FOR_UPDATE
    });

    const [tableInterface] = React.useState(Object.assign({}, DEFAULT_TABLE_CONTEXT));
    const [form] = Form.useForm();
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);
    const [updateRecords, setUpdateRecords] = React.useState([]);
    /* 
        const menuCommand = buildMenuCommand({ form: form, forceUpdateModule: forceUpdate }, buildMenuHandler({
            topLayer,
            setTopLayer,
            form,
            tableInterface,
            destroyDialog: (dlgId) => {
                // нужно через timeout так как после вызова destroyDialog следуют обращения к state
                setTimeout(() => { setTopLayer([...topLayer.filter(c => c.props.id != dlgId)]) }, 100)
            }
        }));
    */

    const setFilters = React.useCallback((config) => {
        tableInterface.requestParams.filters = config;
        tableInterface.refreshData();
    }, [tableInterface])


    const callForm = React.useCallback((id) => {
        editorContext.id = id;
        setFormVisible(true);
    }, [editorContext])
    // тут формируются кнопки
    const buttons = [
        <PanelButton key="add" title="Добавить" onClick={() => callForm()} icon={<PlusOutlined />} />,
        <PanelButton key="refresh" title="Обновить" onClick={() => tableInterface.refreshData()} icon={<RedoOutlined />} />
    ];
    /*
        if (menuCommand) {
             buttons.push(<Dropdown.Button key="more"
                 className="more-dropbutton"
                 trigger="click"
                 overlay={menuCommand} icon={<MoreOutlined />} />);
        }
    */
    if (isMobile()) {
        const filters = buildFilters();
        buttons.push(<FilterButton key="filter" filters={filters}
            onChange={(fc) => setFilters(fc)}
            initValues={initFilters} />);
    }

    const afterEdit = React.useCallback((values) => {
        const flatRecord = values.data.record;
        transformerRecord(flatRecord);
        tableInterface.updateRecord(flatRecord);
        setUpdateRecords([...updateRecords, flatRecord])
    }, [tableInterface, updateRecords])
    const afterAdd = React.useCallback((values) => {
        const flatRecord = values.data.record;
        transformerRecord(flatRecord);
        tableInterface.insFirstRecord(flatRecord);
        setUpdateRecords([...updateRecords, flatRecord])
    }, [tableInterface, updateRecords])

    return (
        <App subsystem={MNU_SUBSYSTEM} menu={MNU_MENU} submenu={MNU_SUMMENU}
            breadcrumb={[{ label: NAME_MENU }, { label: MODULE_TITLE }, { label: ENTITY_TITLE }]}
            afterLogin={forceUpdate}
            buttons={buttons}
            helpId={MODE_HELP_ID}>

            <ModuleHeader
                title={ENTITY_TITLE}
                searchValue={tableInterface.requestParams ? tableInterface.requestParams.search : undefined}
                onSearch={value => {
                    tableInterface.requestParams.search = value;
                    tableInterface.refreshData();
                }}
                buttons={buttons}
            />
            <FilterPanelExt onChange={(fc) => setFilters(fc)} initValues={initFilters}>
                {buildFilters()}
            </FilterPanelExt>
            <DataTable className="mod-main-table"
                uri={{
                    forSelect: URI_FOR_GET_LIST,
                    forDelete: URI_FOR_DELETE
                }}
                columns={COLUMNS}
                exportNameFile='progUsers'
                autoRefresh={AUTO_REFRESH}
                editCallBack={(record) => callForm(record.id)}
                interface={tableInterface}
                onSelectedChange={() => forceUpdate()}
                onAfterRefresh={() => setUpdateRecords([])}
                updateRecords={updateRecords}
                /*
                recordMenu={(record) => recordMenu({
                     topLayer,
                     setTopLayer,
                     form,
                     tableInterface,
                     idName: ENTITY.charAt(0).toLowerCase() + ENTITY.slice(1) + "Id",
                     destroyDialog: (dlgId) => {
                         setTopLayer([...topLayer.filter(c => c.props.id != dlgId)])
                     }
                }, record)}
                */
                transformerData={transformerData}
                quickFilterResetButtonVisible={true}
            />
            <EditForm
                id={EDIT_FORM_ID}
                copyButtonFlag={true}
                visible={formVisible}
                form={form}
                width={FORM_WIDTH}
                editorContext={editorContext}
                afterSave={(response) => {
                    setFormVisible(false);
                    if (response) {
                        if (!editorContext.id) {
                            afterAdd(response)
                        } else {
                            afterEdit(response)
                        }
                    }
                }}
                afterCopy={afterAdd}
                afterCancel={() => {
                    setFormVisible(false);
                }}
                transformerData={transformerRecord}
                idName="id">
                {buildForm(form)}
            </EditForm>
            {topLayer.map(item => item)}
        </App>
    )
}
export default withRouter(ProgUsers);