import React from 'react';
import { Checkbox, Form, Input,Tabs, Space} from 'antd';

const { TabPane } = Tabs;


export const SettingsForm = (props) => {
    const [firstInputRef] = React.useState(React.useRef(null));
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);

    React.useEffect(() => {
        setTimeout(() => {
            if (firstInputRef.current) {
                firstInputRef.current.focus({
                    cursor: 'end',
                })
            }
        }, 100);
    });

    const changePasswordFlag = props.form.getFieldValue("changePasswordFlag")
    console.log("changePasswordFlag=",changePasswordFlag);

    return <Form
        form={props.form}
        layout="vertical"
        name="settingsform-frm"
        onFieldsChange={(state)=>{
            // изменился флаг смены пароля
            const pswdFlag = state.find(f=>f.name=="changePasswordFlag");
            if(pswdFlag) {
                props.form.setFieldsValue({"newPassword":undefined,"confirmPassword":undefined});
                forceUpdate();
            }
            props.onFieldsChange(state);
        }}
        initialValues={props.initialValues}
        >
        
        <Tabs defaultActiveKey="1">
            <TabPane tab="Профиль пользователя" key="1">
            <Form.Item
                    name='id'
                    hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name='changePasswordFlag'
                    valuePropName="checked"
                    >
                   <Checkbox>Сменить пароль</Checkbox>            
                </Form.Item>
                <Space  style={{ display: 'flex'}} align="baseline">
                <Form.Item
                    label="Новый пароль"
                    name='newPassword'
                    hasFeedback
                    rules={[
                        {required: !!changePasswordFlag}
                    ]}>
                    <Input.Password
                        disabled={!props.form.getFieldValue("changePasswordFlag")}
                        autoComplete={"new-password"} />
                </Form.Item>
                <Form.Item
                   label="Подтверждение пароля"
                    name='confirmPassword'
                    dependencies={['newPassword']}
                    hasFeedback
                    rules={[
                        {required: !!changePasswordFlag},
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                              }

                              return Promise.reject(new Error('Значения введенных паролей не совпадают'));
                            },
                          })
                    ]}>
                    <Input.Password
                        disabled={!props.form.getFieldValue("changePasswordFlag")} 
                        autoComplete={"new-password"}/>
                </Form.Item>
                </Space>
                <Form.Item

                    label="Логин"
                    name='login'
                   >
                    <Input readOnly/>
                </Form.Item>
                <Form.Item
                    label="E-mail"
                    name='ident.sysUser.email'
                    rules={[
                        { required: false },
                        { max: 320 },
                        { type: 'email'}
                    ]}>
                    <Input ref={firstInputRef}/>
                </Form.Item>
                <Form.Item
                    label="Телефон"
                    name='ident.sysUser.phone'
                    rules={[
                        { required: false},
                        {max: 16 }
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Имя"
                    name='firstName'
                    rules={[
                        { required: false },
                        { max: 50 }
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Фамилия"
                    name='lastName'
                    rules={[
                        { required: false },
                        { max: 50 }
                    ]}>
                    <Input/>
                </Form.Item>
            </TabPane>
          </Tabs>



    </Form>
}
