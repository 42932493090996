import React from 'react';
import { Form, Input, Tabs, Upload, Button, notification} from 'antd';
import {
    UploadOutlined,PaperClipOutlined,DeleteOutlined,DownloadOutlined
} from '@ant-design/icons';
import {DEVICE_DOC_TYPE_CODE} from '../../lib/Const';
import requestToAPI,{BASE_URL} from "../../lib/Request";
import {prettySizeOf,textAreaKeyDown,saveDataAsFile} from "../../lib/Utils";
import WidgetTypeSelect from "./WidgetTypeSelect";


const { TextArea } = Input;
const { TabPane } = Tabs;




const TypedevicesForm = (props) => {
    const firstInputRef = React.useRef(null);
    const [files] = React.useState({deleteAllFiles:[],insAllFiles:[]});
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);
    const modeAdd = !props.initialValues.id;
    const originalWidgets = props.initialValues.widgets?props.initialValues.widgets:null;
    const [metadata,setMetadata] = React.useState("");


    console.log("originalWidgets",originalWidgets);

    React.useEffect(() => {
        setTimeout(() => {
            if (firstInputRef.current) {
                firstInputRef.current.focus({
                    cursor: 'end',
                })
            }
        }, 100);
    });

    React.useEffect(()=>{
        if(props.initialValues.metadata) {
            setMetadata(JSON.stringify(props.initialValues.metadata,null,'  '));
        }
    },[props.initialValues.metadata])
    
    // выполняется перед валидацией
    props.form.onValidate=React.useCallback(() => {
        if(!modeAdd) {            
            // устанавливаем поле, которые будут переданы в мутацию UPD
            // передаем id удаленных ссылок на файлы
            props.form.setFieldsValue({delfiles_ids:files.deleteAllFiles.map(f=>f.id)});            
            
        }
        // создаем объекты для вставки
        props.form.setFieldsValue({insfiles_obj:files.insAllFiles.map(f=>{
            // ищем файлы с одинаковыми именами
            const arr = files[f.type.code]
                        .filter(f1=>f1.name==f.file.name)
                        .map(f1=>f1.version?parseInt(f1.version):0);
            const maxVers = arr.length>0?Math.max( ...arr):0;
            return {
                fileId: upload(f), //fill after upload
                modelId: props.initialValues.id, // for insert null!
                typeId: f.type.id,
                version: ""+(maxVers+1)
            }
        })});        
    }, [modeAdd,props.form,files.insAllFiles,files.deleteAllFiles,props.initialValues.id])

    props.internalTrigger.onBeforeSave=(values) => {

        if(!modeAdd) {
            const saveWidgets = [...values.widgets];
            //для удаления связанных виджетов находим разность
            const removeWidgets = originalWidgets.filter(w => !saveWidgets.find(sw=>sw.id==w.id));
            values.delwidgets_ids = removeWidgets.map(w=>w.id);

            // для добавления связанных виджетов находим разность
            const insertWidgets = saveWidgets.filter(w => !originalWidgets.find(sw=>sw.id==w.id));
            values.inswidgets_obj = insertWidgets.map(w=>({
                typeId:w.value,
                modelId:props.initialValues.id,
                //options - при необходимости
            }));

        }

        try {
            values.metadata = JSON.parse(metadata);
        } catch(err) {
            notification.error({
                message: "Ошибка разбора метаданных",
                description: err.message
            })
            throw err;
        }

        // удаляем лишнее
        delete values["widgets"];
    };    

    const removeFromCollection=(file)=>{
        const original = props.initialValues.docs.find(f=>f.file==file);
        if(original) {
            const index = props.initialValues.docs.indexOf(original);
            const delfiles = props.initialValues.docs.splice(index, 1);
            if(original.id) {
                files.deleteAllFiles = files.deleteAllFiles.concat(delfiles);
            }
            forceUpdate();
        }
    }
    const addIntoCollection=(file,typeCode)=>{
        const docs=props.initialValues.docs || [];
        docs.push({file:file,type:typeCode})        
        files.insAllFiles=docs.filter(f=>!f.file.url);
        props.initialValues.docs =  docs;
        forceUpdate();
    }

    if(props.initialValues.docs) {
        Object.keys(DEVICE_DOC_TYPE_CODE).forEach(key=>{
            files[key] = props.initialValues.docs
                        .filter(f=>f.type.code==DEVICE_DOC_TYPE_CODE[key].code)
                        .map(f=>{
                            f.file.version = f.version;
                            f.file.note = f.note;
                            return f.file;
                        });
        });
    } else {
        files.manuals=[];
    }


    const upload = (file) => {
        const formData = new FormData();
        formData.append("upload",file.file);
        return requestToAPI.postNative(BASE_URL+"/upload",formData)
            .then(response=>{
                return response.json();
            });
    }

    const fileItemRender = (originNode,file,fileList,actions)=>{
            return <div className="ant-upload-list-text-container">
              <div className="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-text">
                <div className="ant-upload-list-item-info">
                  <span className="ant-upload-span">
                    <PaperClipOutlined />
                    <a className="ant-upload-list-item-name" href={file.url} target="_blank" rel="noreferrer">{file.name}</a>
                    <span className="file-list-info">{prettySizeOf(file.size)}</span>
                    <span className="file-list-info">Версия {file.version}</span>
                    <span className="ant-upload-list-item-card-actions" style={{marginLeft:24}}>
                      <Button onClick={actions.remove} size={"small"} ghost={true} 
                        className="ant-upload-list-item-card-actions-btn" icon={<DeleteOutlined/>}/>
                    </span>
                  </span>
                </div>  
              </div>  
            </div>;
    }

    const uploadMetaData =(state)=>{
        console.log("uploadMetaData");
        var reader = new FileReader();
        reader.onload = function(e){
            setMetadata(e.target.result);
        };
        reader.readAsText(state.file.originFileObj, "UTF-8");
    }

    const downLoadMetaData = ()=>{
        saveDataAsFile(metadata,"metadata.json","text/json");
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
    };

    return<Form
        form={props.form}
        layout="vertical"
        name="formTypeDevice"
        onFieldsChange={props.onFieldsChange}
        initialValues={props.initialValues}>
        <Form.Item
            label="Название"
            name='name'
            rules={[
                { required: true },
                { max: 255 }
            ]}>
            <Input ref={firstInputRef}/>
        </Form.Item>
        <Form.Item
            label="Описание производителя"
            name='note'
            rules={[
                { required: false },
                { max: 255 }
            ]}>
            <TextArea  style={{ height: 60 }} onKeyDown={textAreaKeyDown}/>
        </Form.Item>
        {!modeAdd?    
            <Form.Item
                    label="Связанные виджеты"
                    name='widgets'
                    rules={[
                        { required: false }
                    ]}>
                <WidgetTypeSelect style={{ width: "100%" }}/>
            </Form.Item>
        :""}
        <Form.Item
            label="Ид. профиля устройства TB"
            name='thingsBoardProfileId'
            rules={[
                {
                    message: "Введенное значение в поле не соответсвует формату UUID",
                    pattern: '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
                }
            ]}>
            <Input />
        </Form.Item>
        <Form.Item
            name='delfiles_ids'
            hidden={true}>
            <Input />
        </Form.Item>
        <Form.Item
            name='insfiles_obj'
            hidden={true}>
            <Input />
        </Form.Item>

        <Tabs defaultActiveKey="1">
            {Object.keys(DEVICE_DOC_TYPE_CODE).map(key=> {
                    const docType = DEVICE_DOC_TYPE_CODE[key];
                    return  <TabPane tab={docType.title} key={key}>
                                <Upload fileList={files[key]} 
                                    onRemove={removeFromCollection}
                                    beforeUpload={file=>{
                                        addIntoCollection(file,docType);
                                        return false;
                                    }}
                                    itemRender={fileItemRender}
                                    multiple
                                    accept={docType.accept}
                                    >
                                    <Button title='Загрузить...' icon={<UploadOutlined />}/>
                                </Upload>
                            </TabPane>
                }) 
            }
            <TabPane tab={"Метаданные"} key={"mets"} className="tabmetadata" style={{height:modeAdd?250:170}}>
                <div className='command'>
                <Upload accept='.json' onChange={uploadMetaData} showUploadList={false} customRequest={dummyRequest}> 
                    <Button title='Загрузить...' icon={<UploadOutlined />}/>
                </Upload>
                <Button title='Выгрузить' icon={<DownloadOutlined />} onClick={downLoadMetaData} style={{marginTop:8}}/>
                </div>
                <TextArea readOnly onKeyDown={textAreaKeyDown} value={metadata}/>
            </TabPane>     
        </Tabs>
    </Form>
}

export default TypedevicesForm;