import React from 'react';
import {withRouter} from "react-router";
import ModuleHeader from "../../lib/ModuleHeader";
import {Form} from "antd";
import {DeleteOutlined, PlusOutlined, RedoOutlined} from "@ant-design/icons";
import {CONTOUR_ADMIN, MODULE_CREDENTIAL, MODULE_DEVICE} from "../../lib/ModuleConst";
import {
    MUT_DEL_ISSUE,
    MUT_UPD_ISSUE, MUT_INS_ISSUE,
    QUERY_GET_ISSUE,
    QUERY_GET_LIST_ISSUES, QUERY_GET_DEVICE
} from "../../lib/Query";
import {DEFAULT_TABLE_CONTEXT} from "../../lib/Const";
import App from "../../App";
import {drawDateAndTimeIncSec} from "../../lib/Utils";
import {isMobile, responsiveMobileColumn} from "../../lib/Responsive";
import {FilterButton} from "../../lib/FilterButton";
import {FilterPanelExt} from "../../lib/FilterPanelExt";
import DataTable from "../../lib/DataTable";
import EditForm from "../../lib/EditForm";
import DeviceIssuesForm from "./DeviceIssuesForm";
import requestToAPI from "../../lib/Request";
import { PanelButton } from '../../lib/PanelButton';

const ENTITY_TITLE = "Инциденты прибора";
const MODE_HELP_ID = "/help/proguser";
const CONTOUR = CONTOUR_ADMIN;
const MODULE = MODULE_CREDENTIAL;

// URI для использования формой со списком (текущей) и формой добавления/изменения
const URI_FOR_GET_LIST = QUERY_GET_LIST_ISSUES;
const URI_FOR_GET_ONE = QUERY_GET_ISSUE;
const URI_FOR_GET_DEFAULT = undefined; // когда необходимо вызвать метод с сервера для заполнения данных по умолчанию

const URI_FOR_INSERT = MUT_INS_ISSUE;
const URI_FOR_UPDATE = MUT_UPD_ISSUE;
const URI_FOR_DELETE = MUT_DEL_ISSUE;

const URI_FOR_GET_DEVICE = QUERY_GET_DEVICE;

// позиция в меню
// в subsystem - key верхнего меню
const MNU_SUBSYSTEM = CONTOUR.name;
// в menu - key бокового главного
const MNU_MENU =[MODULE_DEVICE.name,MODULE.name];
const NAME_MENU = "Справочники";
// в submenu - key бокового подменю (финальный пункт)
// его имя равно имени модуля
const MNU_SUMMENU = MODULE_DEVICE.name;
// автоматическое обновление при монтировании компонента
const AUTO_REFRESH = true;

// колонки в таблице
const COLUMNS=[
    {
        title: 'Дата инцидента',
        dataIndex: 'createdInSeconds',
        key: 'createdInSeconds',
        sorter: true,
        ellipsis: true,
        defaultSortOrder: 'descend',
        render:drawDateAndTimeIncSec,

    },
    {
        title: 'Описание',
        dataIndex: 'description',
        key: 'description',
        responsive:responsiveMobileColumn(),

    },
    {
        title: 'Дата решения',
        dataIndex: 'resolvedInSeconds',
        key: 'resolvedInSeconds',
        sorter: true,
        ellipsis: true,
        defaultSortOrder: 'descend',
        render:drawDateAndTimeIncSec,

    },
    {
        title: 'Решение',
        dataIndex: 'resolution',
        key: 'resolution',
        responsive: responsiveMobileColumn(),

    },
];



const transformerRecord = (r)=>{
    /*
     r["docs"]=r.docs.file.url;
     r["docs[fileId]"]=r.docs[fileId];
     delete r.docs
     */
}



const transformerData = (data)=>{
    data.forEach(r =>
        transformerRecord(r)
    );
    return data;
}
// Уникальный идентификатор формы редактировавания
const EDIT_FORM_ID = "device-issues-frm";
// Форма для редактирования
const buildForm = (form, device_id) => {
    return <DeviceIssuesForm device_id={device_id} form={form} initialValues={{}} />
}
// размер формы, -1 - по умолчанию, FORM_MAX_WIDTH - максимальная ширина
const FORM_WIDTH = 680;

// Создание компонент для фильтров
// key это уникальное имя фильтра, попадает в REST API
const buildFilters = () => {
    return <React.Fragment>
        {/*
        <Primary>
            <span>Фильтры</span>
        </Primary>
        <Select key="statusDisplay" style={{ width: 160}} allowClear={true}  placeholder="Статус">
                {statusTypeList.map(value => <Option key={value.id} value={value.id}>{value.title}</Option>)}
            </Select>
        <DateInputRange key="dateRange" allowClear={false} />
        <DataLookup.ProgUser key="servicesUsersName" style={{ width: 240 }} allowClear={true} placeholder="Логин"/>
        <DataLookup.ProgUser key="servicesUserschannelAddress" style={{ width: 240 }} allowClear={true}  placeholder="E-mail" />
        <DataLookup.ProgUser key="servicesUsersphone" style={{ width: 240 }} allowClear={true}  placeholder="Телефон" />
        */}
    </React.Fragment>
}
// начальное значение фильтров
// если значение фильра не объект, а простое значение,
// то значение имени свойства компонента принимается как defaultValue компонента
const initFilters = {
}

// дополнительные команды
// если меню нет, то и кнопки нет

/*
const buildMenuCommand = (config, handleMenuClick) => {
    return <Menu onClick={handleMenuClick}>
        {buildPrintMenu(MODULE.name, config)}
    </Menu>
};

// обрабочик меню
const buildMenuHandler = (config) => {
    return (ev) => {
        console.log('click', ev);
    }
}
*/

// меню для записи
// const recordMenu = (config, record) => (
//     <React.Fragment>
//         {buildEntityPrintMenu(ENTITY, record, config)}
//         <Menu.Divider />
//         <Menu.Item key="manageAccessRole" icon={<UsergroupAddOutlined />} onClick={(ev) => manageAccessRole(ev, config, record)}>Роли</Menu.Item>
//         <Menu.Item key="setPasswordProgUser" onClick={(ev) => setPasswordProgUser(ev, config, record)}>Установить пароль</Menu.Item>
//     </React.Fragment>
// )

//===============================================================================
// Основной функциональный компонент
//===============================================================================
/**
 * Таблица передает на сервер post-запрос в теле которого
 * pagination - информация о странице
 * sort - сортировка
 * filters - фильтры (+ быстрые фильтры начинаются с quick.*)
 * search - строка полнотекстового поиска
 */

const DeviceIssues = (props) => {
    let [formVisible, setFormVisible] = React.useState(false);
    let [data, setData] = React.useState({});
    const [topLayer] = React.useState([]);
    const device_id = props.match.params.id

    let [editorContext] = React.useState({
        uriForGetOne: URI_FOR_GET_ONE,
        uriForGetDefault: URI_FOR_GET_DEFAULT,
        uriForInsert:URI_FOR_INSERT,
        uriForUpdate:URI_FOR_UPDATE
    });

    React.useEffect(() => {
        requestToAPI.post(URI_FOR_GET_DEVICE, {id: device_id})
            .then(response => {
                setData(response.data.record)
            })
            .catch(error => {
                console.log(error);
            })
    }, [device_id]);

    const [tableInterface] = React.useState(Object.assign({}, DEFAULT_TABLE_CONTEXT));
    const [form] = Form.useForm();
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);
    const [updateRecords, setUpdateRecords] = React.useState([]);
    /*
        const menuCommand = buildMenuCommand({ form: form, forceUpdateModule: forceUpdate }, buildMenuHandler({
            topLayer,
            setTopLayer,
            form,
            tableInterface,
            destroyDialog: (dlgId) => {
                // нужно через timeout так как после вызова destroyDialog следуют обращения к state
                setTimeout(() => { setTopLayer([...topLayer.filter(c => c.props.id != dlgId)]) }, 100)
            }
        }));
    */

    const setFilters = React.useCallback((config) => {
        tableInterface.requestParams.filters = config;
        tableInterface.refreshData();
    }, [tableInterface])


    const callForm = React.useCallback((id) => {
        editorContext.id = id;
        setFormVisible(true);
    }, [editorContext])

    // тут формируются кнопки
    const buttons = [
        <PanelButton title="Добавить" key="add" onClick={() => callForm()} icon={<PlusOutlined />} />,
        <PanelButton title="Удалить" key="del" onClick={() => tableInterface.deleteData()} disabled={tableInterface.isLoading() || tableInterface.getSelectedRows().length == 0} icon={<DeleteOutlined />} />,
        <PanelButton title="Обновить" key="refresh" onClick={() => tableInterface.refreshData()} icon={<RedoOutlined />} />
    ];

    if(isMobile()) {
        const filters = buildFilters();
        buttons.push(<FilterButton key="filter" filters={filters}
                                   onChange={(fc) => setFilters(fc)}
                                   initValues={initFilters}/>);
    }

    const afterEdit = React.useCallback((values) => {
        const flatRecord =  values.data.record;
        transformerRecord(flatRecord);
        tableInterface.updateRecord(flatRecord);
        setUpdateRecords([...updateRecords, flatRecord])
    }, [tableInterface, updateRecords])

    const afterAdd = React.useCallback((values) => {
        const flatRecord =  values.data.record;
        transformerRecord(flatRecord);
        tableInterface.insFirstRecord(flatRecord);
        setUpdateRecords([...updateRecords, flatRecord])
    }, [tableInterface, updateRecords])

    return (
        <App subsystem={MNU_SUBSYSTEM} menu={MNU_MENU} submenu={MNU_SUMMENU}
             breadcrumb={[{ label: NAME_MENU }, { label: `Прибор: ${data.number}` }, { label: ENTITY_TITLE }]}
             afterLogin={forceUpdate}
             buttons={buttons}
             helpId={MODE_HELP_ID}>

            <ModuleHeader
                title={`${ENTITY_TITLE}:  ${data.number} ${data.note ? `(${data.note})` : '' }`}
                searchValue={tableInterface.requestParams?tableInterface.requestParams.search:undefined}
                onSearch={value => {
                    tableInterface.requestParams.search = value;
                    tableInterface.refreshData();
                }}
                buttons={buttons}
            />
            <FilterPanelExt onChange={(fc) => setFilters(fc)} initValues={initFilters}>
                {buildFilters()}
            </FilterPanelExt>
            <DataTable className="mod-main-table"
                       uri={{
                           forSelect: URI_FOR_GET_LIST,
                           forDelete: URI_FOR_DELETE
                       }}
                       columns={COLUMNS}
                       exportNameFile='deviceIssues'
                       autoRefresh={AUTO_REFRESH}
                       editCallBack={(record) => callForm(record.id)}
                       interface={tableInterface}
                       onSelectedChange={() => forceUpdate()}
                       onBeforeRefresh={() => {
                           tableInterface.requestParams.filters.unitId = device_id;
                           return true;
                       }}
                       onAfterRefresh={() => setUpdateRecords([])}
                       updateRecords={updateRecords}
                       transformerData={transformerData}
                       defaultFilters={initFilters}
                       quickFilterResetButtonVisible={true}

                /*
                recordMenu={(record) => recordMenu({
                     topLayer,
                     setTopLayer,
                     form,
                     tableInterface,
                     idName: ENTITY.charAt(0).toLowerCase() + ENTITY.slice(1) + "Id",
                     destroyDialog: (dlgId) => {
                         setTopLayer([...topLayer.filter(c => c.props.id != dlgId)])
                     }
                }, record)}
                */
            />
            <EditForm
                id={EDIT_FORM_ID}
                copyButtonFlag={true}
                visible={formVisible}
                form={form}
                width={FORM_WIDTH}
                editorContext={editorContext}
                afterSave={(response) => {
                    setFormVisible(false);
                    if (response) {
                        if (!editorContext.id) {
                            afterAdd(response)
                        } else {
                            afterEdit(response)
                        }
                    }
                }}
                transformerData={transformerRecord}
                afterCopy={afterAdd}
                afterCancel={() => {
                    setFormVisible(false);
                }}
                idName="id">
                {buildForm(form, device_id)}
            </EditForm>
            {topLayer.map(item => item)}
        </App>
    )
}
export default withRouter(DeviceIssues);