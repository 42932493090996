import React from 'react';
import { Form, Input } from 'antd';
import {DateInput} from "../../lib/DateInput";
import moment from 'moment';
import {DEFAULT_DATETIME_FORMAT} from "../../lib/Const";
import {textAreaKeyDown} from "../../lib/Utils";

const { TextArea } = Input;

const DeviceIssuesForm = (props) => {
    let values = {...props.initialValues};
    const firstInputRef = React.useRef(null);
    const modeAdd = !props.initialValues.id;


    React.useEffect(() => {
        setTimeout(() => {
            if (firstInputRef.current) {
                firstInputRef.current.focus({
                    cursor: 'end',
                })
            }
        }, 100);
    });
    if(modeAdd) {
        values.created=moment();
        values.resolved=null;

    } else {

        values.created=values.createdInSeconds
            ? moment(values.createdInSeconds*1000)
            : undefined;
        if (values.resolved !== null){
            values.resolved=values.resolvedInSeconds
                ? moment(values.resolvedInSeconds*1000)
                : undefined;
        }

    }

    // выполняется после валидацией, но до сохранения
    props.internalTrigger.onBeforeSave=(validvalues) => {

        if(!modeAdd) {
            // delete validvalues.created;
            // delete validvalues.resolved;

        } else {
            validvalues.unitId = props.device_id
            validvalues.created = validvalues.created.toDate().toISOString();
            if (validvalues.resolved !== null){
                validvalues.resolved = validvalues.resolved.toDate().toISOString();
            }

        }


    };

    return  <Form
        form={props.form}
        layout="horizontal"
        name="formDeviceIssues"
        onFieldsChange={props.onFieldsChange}
        initialValues={values}>
        <Form.Item style={{ marginBottom: -2}}>
            Регистрация инцидента
        </Form.Item>
        <Form.Item
            name="created"
            rules={[
                { required: true },
            ]}>
            <DateInput disabled={!modeAdd} allowClear={false}
                       format={DEFAULT_DATETIME_FORMAT}
                       placeholder="Дата инцидента" style={{width:200}}/>
        </Form.Item>
        <Form.Item
            name='description'
            rules={[
                { required: true },
                { max: 255 }
            ]}>
            <TextArea placeholder="Описание инцидента" style={{ height: 100 }} onKeyDown={textAreaKeyDown}/>
        </Form.Item>
        <Form.Item  style={{ marginBottom: -2}}>
            Решение
        </Form.Item>
        <Form.Item
            name="resolved"
            rules={[
            ]}>
            <DateInput allowClear={false}
                       format={DEFAULT_DATETIME_FORMAT}
                       placeholder="Дата решения" style={{width:200}}/>
        </Form.Item>
        <Form.Item
            name='resolution'
            rules={[
                { required: false },
                { max: 255 }
            ]}>
            <TextArea placeholder="Описание решения" style={{ height: 100 }}/>
        </Form.Item>
    </Form>

}

export default DeviceIssuesForm;