import React from 'react';
import {QUERY_GET_WIGET_TYPE} from "../../lib/Query";
import MultiDataSelect from "../../lib/MultiDataSelect"

const WidgetTypeSelect = React.forwardRef((props, ref) => {
    const {...otherprops} = props;

    return <MultiDataSelect ref={ref}
        uri={QUERY_GET_WIGET_TYPE}
        SelectProps={{
            maxTagCount:'responsive',
        }}
        allowClear={true}
        valueName="id"
        displayValueName="note"
        {...otherprops}
    />    
});

export default WidgetTypeSelect;
