import React from 'react';
import {QUERY_GET_AUDIT_TYPE} from "../../lib/Query";
import MultiDataSelect from "./../../lib/MultiDataSelect"

const AuditEventSelect = React.forwardRef((props, ref) => {
    const {...otherprops} = props;

    return <MultiDataSelect ref={ref}
        uri={QUERY_GET_AUDIT_TYPE}
        SelectProps={{
            maxTagCount:'responsive',
        }}
        allowClear={true}
        valueName="id"
        displayValueName="name"
        {...otherprops}
    />    
});

export default AuditEventSelect;
