import React from 'react';
import { Form } from 'antd';
import DataTable from "../../lib/DataTable";
import App from '../../App';
import ModuleHeader from "../../lib/ModuleHeader";
import { FilterPanelExt } from "../../lib/FilterPanelExt";
import {FilterButton} from '../../lib/FilterButton';
import { withRouter } from "react-router";
import { DEFAULT_TABLE_CONTEXT} from "../../lib/Const"; 
import EditForm from "../../lib/EditForm";
import TypeDevicesForm from "./TypeDevicesForm";
import { CONTOUR_ADMIN, MODULE_CREDENTIAL, MODULE_TYPEDEVICES } from "../../lib/ModuleConst";
import {responsiveMobileColumn, isMobile} from '../../lib/Responsive';
import {
    PlusOutlined, RedoOutlined, DeleteOutlined, FilePdfOutlined, FileWordOutlined, FileTextOutlined
} from '@ant-design/icons';
import {MUT_INS_TYPEDEVICE,MUT_UPD_TYPEDEVICE, QUERY_GET_LIST_TYPEDEVICE, QUERY_GET_TYPEDEVICE, 
    MUT_DEL_TYPEDEVICE,MUT_INS_TYPEDEVICE_DOC, QUERY_MUT_PROCESS_FIRMWARE} from "../../lib/Query";
import requestToAPI from "../../lib/Request";
import { useHistory } from "react-router-dom";
import { PanelButton } from '../../lib/PanelButton';


const ENTITY_TITLE = "Типы Приборов";
const MODE_HELP_ID = "/help/proguser";
const CONTOUR = CONTOUR_ADMIN;
const MODULE = MODULE_CREDENTIAL;

// URI для использования формой со списком (текущей) и формой добавления/изменения
const URI_FOR_GET_LIST = QUERY_GET_LIST_TYPEDEVICE;
const URI_FOR_GET_ONE = QUERY_GET_TYPEDEVICE;
const URI_FOR_GET_DEFAULT = undefined; // когда необходимо вызвать метод с сервера для заполнения данных по умолчанию

const URI_FOR_UPDATE = MUT_UPD_TYPEDEVICE;
const URI_FOR_DELETE = MUT_DEL_TYPEDEVICE;

// позиция в меню   
// в subsystem - key верхнего меню
const MNU_SUBSYSTEM = CONTOUR.name;
// в menu - key бокового главного
const MNU_MENU =[MODULE_TYPEDEVICES.name,MODULE.name];
const NAME_MENU = "Справочники";
// в submenu - key бокового подменю (финальный пункт)
// его имя равно имени модуля
const MNU_SUMMENU = MODULE_TYPEDEVICES.name + ".sm1";
// автоматическое обновление при монтировании компонента
const AUTO_REFRESH = true;

const getIconFile = (mimeType) => {

    switch (mimeType) {
        case "application/pdf":
            return <FilePdfOutlined /> 
        case "application/docx":
            return <FileWordOutlined />
        default :
            return <FileTextOutlined />
    }
}

// колонки в таблице
const COLUMNS = [
    {
        title: 'Название',
        dataIndex: 'name',
        sorter: true,
        ellipsis: true,
    },
    {
        title: 'Паспорт и РЭ',
        dataIndex: 'docs',
        disableQuickFilter: true,
        responsive:responsiveMobileColumn(),
        render: (data) => {
            return data
                .filter(f => f.type.code == "manuals")
                .map((f, idx) => { 
                    return <React.Fragment key={"file"+idx}><a className="typedevice"
                                href="/"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    window.open(f.file.url, "_blank");
                                    return true;
                                }}>{getIconFile(f.file.mimeType)}{" " + f.file.name + " "}
                            </a>{" "}</React.Fragment>
                    
            });
                    
        },
    }
]

const transformerRecord = (r)=>{
    if(r.widgets) {
        r.widgets=r.widgets.map(w=>({id:w.id,value:w.typeId,title:w.type.note}))
    }
}    
   

const transformerData = (data)=>{
    data.forEach(r => 
        transformerRecord(r)
    );
    return data;
}
// Уникальный идентификатор формы редактировавания
const EDIT_FORM_ID = "typedevices-frm";
// Форма для редактирования
const buildForm = (form) => {
    return <TypeDevicesForm form={form} initialValues={{delfiles_ids:[],insfiles_obj:[]}} />
}
// размер формы, -1 - по умолчанию, FORM_MAX_WIDTH - максимальная ширина
const FORM_WIDTH = 800;

// Создание компонент для фильтров
// key это уникальное имя фильтра, попадает в REST API
const buildFilters = () => {
    return <React.Fragment>
        {/*
        <Primary>
            <span>Фильтры</span>
        </Primary>
        <Select key="statusDisplay" style={{ width: 160}} allowClear={true}  placeholder="Статус">
                {statusTypeList.map(value => <Option key={value.id} value={value.id}>{value.title}</Option>)}
            </Select>
        <DateInputRange key="dateRange" allowClear={false} />
        <DataLookup.ProgUser key="servicesUsersName" style={{ width: 240 }} allowClear={true} placeholder="Логин"/>
        <DataLookup.ProgUser key="servicesUserschannelAddress" style={{ width: 240 }} allowClear={true}  placeholder="E-mail" />
        <DataLookup.ProgUser key="servicesUsersphone" style={{ width: 240 }} allowClear={true}  placeholder="Телефон" />
        */}
    </React.Fragment>
}
// начальное значение фильтров
// если значение фильра не объект, а простое значение,
// то значение имени свойства компонента принимается как defaultValue компонента
const initFilters = {
}

// дополнительные команды
// если меню нет, то и кнопки нет

/*
const buildMenuCommand = (config, handleMenuClick) => {
    return <Menu onClick={handleMenuClick}>
        {buildPrintMenu(MODULE.name, config)}
    </Menu>
};
 
// обрабочик меню
const buildMenuHandler = (config) => {
    return (ev) => {
        console.log('click', ev);
    }
}
*/

// меню для записи
/*
const recordMenu = (config, record) => (
     <React.Fragment>
         {buildEntityPrintMenu(ENTITY, record, config)}
         <Menu.Divider />
         <Menu.Item key="manageAccessRole" icon={<UsergroupAddOutlined />} onClick={(ev) => manageAccessRole(ev, config, record)}>Роли</Menu.Item>
         <Menu.Item key="setPasswordProgUser" onClick={(ev) => setPasswordProgUser(ev, config, record)}>Установить пароль</Menu.Item>
     </React.Fragment>
)
*/

const uploadAllFiles = (insfiles_obj)=>{
    let promises = [];
    insfiles_obj.forEach(f=>{
        promises.push(f.fileId);
    })
    return Promise.all(promises);
}

//===============================================================================
// Основной функциональный компонент
//===============================================================================
  
const TypeDevices = (props) => {
    let [formVisible, setFormVisible] = React.useState(false);
    const [topLayer] = React.useState([]);

    let [editorContext] = React.useState({
        uriForGetOne: URI_FOR_GET_ONE,
        uriForGetDefault: URI_FOR_GET_DEFAULT,
        
        // одним запросом graphQL не обойтись, так как требуется 
        // встатавить документы и загрузить файлы
        uriForInsert:(values) =>{
            const {insfiles_obj,delfiles_ids,...changeTypeDevValues} = values;
            return uploadAllFiles(insfiles_obj)
                .then((jsons)=>{
                    // заменяем промисы возвращенными id загруженных файлов
                    jsons.forEach((r,index)=>values.insfiles_obj[index].fileId=r.id);
                    return requestToAPI.post(MUT_INS_TYPEDEVICE,changeTypeDevValues)
                })
                .then(response=>{
                    insfiles_obj.forEach(f=>f.modelId=response.data.record.id);
                    return requestToAPI.post(MUT_INS_TYPEDEVICE_DOC,{insfiles_obj})
                        .then((docresponse)=>{
                            response.data.record.docs=docresponse.data.records.returning;
                            response.data.insertdoc = {}
                            response.data.insertdoc.returning = docresponse.data.records.returning
                            return response;
                        })
                        .catch(error=>{
                            console.error(error);
                            throw error;
                        })    
                })
        },
        uriForUpdate:(values) =>{
            return uploadAllFiles(values.insfiles_obj)
                .then((jsons)=>{
                    // заменяем промисы возвращенными id загруженных файлов
                    jsons.forEach((r,index)=>values.insfiles_obj[index].fileId=r.id);
                    return requestToAPI.post(URI_FOR_UPDATE,values);
                })  
        }    
    });

    const [tableInterface] = React.useState(Object.assign({}, DEFAULT_TABLE_CONTEXT));
    const [form] = Form.useForm();
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);
    const [updateRecords, setUpdateRecords] = React.useState([]);
/* 
    const menuCommand = buildMenuCommand({ form: form, forceUpdateModule: forceUpdate }, buildMenuHandler({
        topLayer,
        setTopLayer,
        form,
        tableInterface,
        destroyDialog: (dlgId) => {
            // нужно через timeout так как после вызова destroyDialog следуют обращения к state
            setTimeout(() => { setTopLayer([...topLayer.filter(c => c.props.id != dlgId)]) }, 100)
        }
    }));
*/

    const setFilters = React.useCallback((config) => {
        tableInterface.requestParams.filters = config;
        tableInterface.refreshData();
    }, [tableInterface])


    const callForm = React.useCallback((id) => {
        editorContext.id = id;
        setFormVisible(true);
    }, [editorContext])
    // тут формируются кнопки
    const buttons = [   
        <PanelButton title = "Добавить" key="add" onClick={() => callForm()} icon={<PlusOutlined />} />,
        <PanelButton title="Удалить" key="del" onClick={() => tableInterface.deleteData()} disabled={tableInterface.isLoading() || tableInterface.getSelectedRows().length == 0} icon={<DeleteOutlined />} />,
        <PanelButton title = "Обновить" key="refresh" onClick={() => tableInterface.refreshData()} icon={<RedoOutlined />} />
    ];
/*
    if (menuCommand) {
         buttons.push(<Dropdown.Button key="more"
             className="more-dropbutton"
             trigger="click"
             overlay={menuCommand} icon={<MoreOutlined />} />);
    }
*/
    if(isMobile()) {
        const filters = buildFilters();
        buttons.push(<FilterButton key="filter" filters={filters} 
                        onChange={(fc) => setFilters(fc)} 
                        initValues={initFilters}/>);
    }

    const afterEdit = React.useCallback((values) => {
        const flatRecord =  values.data.record;
        transformerRecord(flatRecord);        
        tableInterface.updateRecord(flatRecord);
        setUpdateRecords([...updateRecords, flatRecord])
    }, [tableInterface, updateRecords])
    const afterAdd = React.useCallback((values) => {
        const flatRecord =  values.data.record;
        transformerRecord(flatRecord);        
        tableInterface.insFirstRecord(flatRecord);
        setUpdateRecords([...updateRecords, flatRecord])
        }, [tableInterface, updateRecords])

    const history = useHistory();
    // прямая ссылка
    if(props.match.params.id && !formVisible) {
        callForm(props.match.params.id);
    }
    const stopLinkDirect = ()=>{
        if(props.match.params.id) {
            history.goBack();
        }    
    }

    async function insertProcessFirmware(data) {
        for (let i = 0; i < data.length; i++) {
            // загружаемые файлы соответсвуют прошивке
            if (data[i].typeId === 5) {
                await requestToAPI.post(QUERY_MUT_PROCESS_FIRMWARE,
                    {
                        fileId: data[i].fileId,
                        modelId: data[i].modelId
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        }
    }

    return (
        <App subsystem={MNU_SUBSYSTEM} menu={MNU_MENU} submenu={MNU_SUMMENU}
            breadcrumb={[{ label: NAME_MENU },{ label: ENTITY_TITLE }]}
            afterLogin={forceUpdate}
            buttons={buttons}
            helpId={MODE_HELP_ID}>
            
            <ModuleHeader
                title={ENTITY_TITLE}
                searchValue={tableInterface.requestParams?tableInterface.requestParams.search:undefined}
                onSearch={value => {
                    tableInterface.requestParams.search = value;
                    tableInterface.refreshData();
                }}
                buttons={buttons}
            />
            <FilterPanelExt onChange={(fc) => setFilters(fc)} initValues={initFilters}>
                {buildFilters()}
            </FilterPanelExt>
            <DataTable className="mod-main-table"
                uri={{
                    forSelect: URI_FOR_GET_LIST,
                    forDelete: URI_FOR_DELETE
                }}
                columns={COLUMNS}
                exportNameFile='typeDevices'
                autoRefresh={AUTO_REFRESH && !props.match.params.id}
                editCallBack={(record) => callForm(record.id)}
                interface={tableInterface}
                onSelectedChange={() => forceUpdate()}
                onAfterRefresh={() => setUpdateRecords([])}
                updateRecords={updateRecords}
                transformerData={transformerData}
                quickFilterResetButtonVisible={true}
                /*
                recordMenu={(record) => recordMenu({
                     topLayer,
                     setTopLayer,
                     form,
                     tableInterface,
                     idName: ENTITY.charAt(0).toLowerCase() + ENTITY.slice(1) + "Id",
                     destroyDialog: (dlgId) => {
                         setTopLayer([...topLayer.filter(c => c.props.id != dlgId)])
                     }
                }, record)}
                */
            />
            <EditForm
                id={EDIT_FORM_ID}
                copyButtonFlag={true}
                visible={formVisible}
                form={form}
                width={FORM_WIDTH}
                editorContext={editorContext}
                transformerData={transformerRecord}
                // это нужно чтобы достать данные выше стандартного record
                afterLoad={(response)=>{
                    response.record.widgets=response.widgets;
                    delete response.widgets;
                }}
                afterSave={(response) => {
                    // загруженные документы
                    let data = response.data.insertdoc.returning
                    if (data.length > 0) {
                        insertProcessFirmware(data)
                    }

                    setFormVisible(false);
                    if (response) {

                        if (!editorContext.id) {
                            afterAdd(response)
                        } else {
                            afterEdit(response)
                        }
                    }
                    stopLinkDirect();
                }}
                afterCopy={afterAdd}
                afterCancel={() => {
                    setFormVisible(false);
                    stopLinkDirect();
                }}
                idName="id">
                {buildForm(form)}
            </EditForm>
            {topLayer.map(item => item)}
        </App>
    )
}
export default withRouter(TypeDevices);