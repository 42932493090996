import React from 'react';
import { Form, Input,Checkbox } from 'antd';

const ServicesUsersForm = (props) => {
    const firstInputRef = React.useRef(null);
    const modeAdd = !props.initialValues.id;

    React.useEffect(() => {
        setTimeout(() => {
            if (firstInputRef.current) {
                firstInputRef.current.focus({
                    cursor: 'end',
                })
            }
        }, 100);
    });

    return <Form
        form={props.form}
        layout="vertical"
        name="formServicesUsers"
        onFieldsChange={props.onFieldsChange}
        initialValues={props.initialValues}>
        <Form.Item
            label="Логин"
            name={modeAdd?"login":undefined}
            rules={[
                { required: true },
                { max: 50 }
            ]}>
            <Input value={!modeAdd?props.initialValues.login:undefined} ref={firstInputRef} disabled={!modeAdd}/>
        </Form.Item>
        <Form.Item
            label="Имя"
            name='firstName'
            rules={[
                { required: false },
                { max: 50 }
            ]}>
            <Input ref={firstInputRef}/>
        </Form.Item>

        <Form.Item
            label="Фамилия"
            name='lastName'
            rules={[
                { required: false },
                { max: 50 }
            ]}>
            <Input />
        </Form.Item>


        <Form.Item
            label="Отчество"
            name='patronymic'
            rules={[
                { required: false },
                { max: 50 }
            ]}>
            <Input />
        </Form.Item>

        <Form.Item
            label="E-mail"
            name='ident.sysUser.email'
            rules={[
                { required: false },
                { max: 320 },
                { type: 'email'}
            ]}>
            <Input />
        </Form.Item>
        <Form.Item
            label="Телефон"
            name='ident.sysUser.phone'
            rules={[
                { required: false},
                {max: 16 }
            ]}>
            <Input />
        </Form.Item>
        <Form.Item
            hidden={modeAdd}
            className='blocked'
            name='ident.blocked'
            label='Блокировка'
            valuePropName="checked"
        >
            <Checkbox/>
        </Form.Item>
    </Form>
}

export default ServicesUsersForm;