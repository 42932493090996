import React from 'react';
import { Form, Input, Checkbox } from 'antd';

const ProguserForm = (props) => {
    const firstInputRef = React.useRef(null);
    const modeAdd = !props.initialValues.id;

    React.useEffect(() => {
        setTimeout(() => {
            if (firstInputRef.current) {
                firstInputRef.current.focus({
                    cursor: 'end',
                })
            }
        }, 100);
    });

    return<Form
        form={props.form}
        layout="vertical"
        name="formProgUsers"
        onFieldsChange={props.onFieldsChange}
        initialValues={props.initialValues}>
        <Form.Item
            label="Логин"
            name={modeAdd?"login":undefined}
            rules={[
                { required: true },
                { max: 50 }
            ]}>
            <Input value={!modeAdd?props.initialValues.login:undefined} ref={firstInputRef} disabled={!modeAdd}/>
        </Form.Item>
        <Form.Item
            label="Имя"
            name='firstName'
            rules={[
                { required: true },
                { max: 50 }
            ]}>
            <Input ref={firstInputRef}/>
        </Form.Item>

        <Form.Item
            label="Фамилия"
            name='lastName'
            rules={[
                { required: true },
                { max: 50 }
            ]}>
            <Input />
        </Form.Item>


        <Form.Item
            label="Отчество"
            name='patronymic'
            rules={[
                { required: false },
                { max: 50 }
            ]}>
            <Input />
        </Form.Item>

        <Form.Item
            label="E-mail"
            name='ident.devUser.email'
            rules={[
                { required: false },
                { max: 320 },
                { type: 'email'}
            ]}>
            <Input />
        </Form.Item>
        <Form.Item
            label="Телефон"
            name='ident.devUser.phone'
            rules={[
                { required: false},
                {max: 16 }
            ]}>
            <Input  />
        </Form.Item>
        <Form.Item
            label="Блокировка"
            className="blocked"
            name='ident.blocked'
            valuePropName="checked"
        >
            <Checkbox/>
        </Form.Item>
    </Form>
}

export default ProguserForm;