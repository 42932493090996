import React from 'react';
import { Form, Menu, notification } from 'antd';
import DataTable,{ColumnTypes} from "../../lib/DataTable";
import App from '../../App';
import ModuleHeader from "../../lib/ModuleHeader";
import { FilterPanelExt } from "../../lib/FilterPanelExt";
import {FilterButton} from '../../lib/FilterButton';
import { withRouter } from "react-router";
import { DEFAULT_TABLE_CONTEXT } from "../../lib/Const";
import EditForm from "../../lib/EditForm";
import DeviceForm from "./DeviceForm";
import { CONTOUR_ADMIN, MODULE_CREDENTIAL, MODULE_DEVICE } from "../../lib/ModuleConst";
import {responsiveMobileColumn, isMobile} from '../../lib/Responsive';
import { drawDateAndTimeIncSec} from "../../lib/Utils";
import {
    PlusOutlined, RedoOutlined
} from '@ant-design/icons';
import {MUT_INS_DEVICE, MUT_UPD_DEVICE, QUERY_GET_LIST_DEVICE, QUERY_GET_DEVICE, QUERY_DETACH_DEVICE} from "../../lib/Query";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { PanelButton } from '../../lib/PanelButton';
import { ApiOutlined } from '@ant-design/icons'
import { confirm } from "../../lib/Dialogs";
import requestToAPI from "../../lib/Request";


const ENTITY_TITLE = "Приборы";
const MODE_HELP_ID = "/help/proguser";
const CONTOUR = CONTOUR_ADMIN;
const MODULE = MODULE_CREDENTIAL;

// URI для использования формой со списком (текущей) и формой добавления/изменения
const URI_FOR_GET_LIST = QUERY_GET_LIST_DEVICE;
const URI_FOR_GET_ONE = QUERY_GET_DEVICE;
const URI_FOR_GET_DEFAULT = undefined; // когда необходимо вызвать метод с сервера для заполнения данных по умолчанию

const URI_FOR_INSERT = MUT_INS_DEVICE;
const URI_FOR_UPDATE = MUT_UPD_DEVICE;
const URI_FOR_DELETE = undefined;

const URI_FOR_DETACH_DEVICE = QUERY_DETACH_DEVICE;

// позиция в меню   
// в subsystem - key верхнего меню
const MNU_SUBSYSTEM = CONTOUR.name;
// в menu - key бокового главного
const MNU_MENU =[MODULE_DEVICE.name,MODULE.name];
const NAME_MENU = "Справочники";
// в submenu - key бокового подменю (финальный пункт)
// его имя равно имени модуля
const MNU_SUMMENU = MODULE_DEVICE.name;
// автоматическое обновление при монтировании компонента
const AUTO_REFRESH = true;

// колонки в таблице
const COLUMNS = [
    {
        title: 'Произведен',
        dataIndex: 'manufacturedInSeconds',
        dataType:ColumnTypes.DATETIME,
        sorter: true,
        ellipsis: true,
        defaultSortOrder: 'ascend',
        render:drawDateAndTimeIncSec,
        renderForFilter:(data)=>moment(data*1000).format("DD.MM.YYYY HH:mm")
    },
    {
        title: 'Серийный номер',
        dataIndex: 'number',
        sorter: true,
        ellipsis: true,
    },
    {
        title: 'Владелец',
        dataIndex: 'owner.login',
        sorter: true,
        ellipsis: true,
        responsive:responsiveMobileColumn(),
    },
    {
        title: 'Описание производителя',
        dataIndex: 'note',
        sorter: true,
        ellipsis: true,
        responsive:responsiveMobileColumn(),
    },
{
        title: 'Тип прибора',
        dataIndex: 'model.name',
        sorter: true,
        ellipsis: true,
        responsive:responsiveMobileColumn(),
        render:(typeName, record) => {
            return <Link className="table-field-as-link" to={"/typedevices/"+record["model.id"]}>{typeName}</Link>
        }    
    },
]

const transformerRecord = (r)=>{
    r["model.name"]=r.model.name;
    r["model.id"]=r.model.id;
    r["owner.login"]=r.owner?r.owner.login:undefined;
    delete r.model; 
    delete r.owner;
}    
   

const transformerData = (data)=>{
    data.forEach(r => 
        transformerRecord(r)
    );
    return data;
}
// Уникальный идентификатор формы редактировавания
const EDIT_FORM_ID = "device-frm";
// Форма для редактирования
const buildForm = (form) => {
    return <DeviceForm form={form} initialValues={{}} />
}
// размер формы, -1 - по умолчанию, FORM_MAX_WIDTH - максимальная ширина
const FORM_WIDTH = 680;

// Создание компонент для фильтров
// key это уникальное имя фильтра, попадает в REST API
const buildFilters = () => {
    return <React.Fragment>
        {/*
        <Primary>
            <span>Фильтры</span>
        </Primary>
        <Select key="statusDisplay" style={{ width: 160}} allowClear={true}  placeholder="Статус">
                {statusTypeList.map(value => <Option key={value.id} value={value.id}>{value.title}</Option>)}
            </Select>
        <DateInputRange key="dateRange" allowClear={false} />
        <DataLookup.ProgUser key="servicesUsersName" style={{ width: 240 }} allowClear={true} placeholder="Логин"/>
        <DataLookup.ProgUser key="servicesUserschannelAddress" style={{ width: 240 }} allowClear={true}  placeholder="E-mail" />
        <DataLookup.ProgUser key="servicesUsersphone" style={{ width: 240 }} allowClear={true}  placeholder="Телефон" />
        */}
    </React.Fragment>
}
// начальное значение фильтров
// если значение фильра не объект, а простое значение,
// то значение имени свойства компонента принимается как defaultValue компонента
const initFilters = {
}

// дополнительные команды
// если меню нет, то и кнопки нет

/*
const buildMenuCommand = (config, handleMenuClick) => {
    return <Menu onClick={handleMenuClick}>
        {buildPrintMenu(MODULE.name, config)}
    </Menu>
};
 
// обрабочик меню
const buildMenuHandler = (config) => {
    return (ev) => {
        console.log('click', ev);
    }
}
*/

//===============================================================================
// Основной функциональный компонент
//===============================================================================
/**
 * Таблица передает на сервер post-запрос в теле которого
 * pagination - информация о странице
 * sort - сортировка
 * filters - фильтры (+ быстрые фильтры начинаются с quick.*)
 * search - строка полнотекстового поиска
 */
  
const Device = (props) => {
    let [formVisible, setFormVisible] = React.useState(false);
    const [topLayer] = React.useState([]);

    let [editorContext] = React.useState({
        uriForGetOne: URI_FOR_GET_ONE,
        uriForGetDefault: URI_FOR_GET_DEFAULT,
        uriForInsert:URI_FOR_INSERT,
        uriForUpdate:URI_FOR_UPDATE
    });

    const [tableInterface] = React.useState(Object.assign({}, DEFAULT_TABLE_CONTEXT));
    const [form] = Form.useForm();
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);
    const [updateRecords, setUpdateRecords] = React.useState([]);
/* 
    const menuCommand = buildMenuCommand({ form: form, forceUpdateModule: forceUpdate }, buildMenuHandler({
        topLayer,
        setTopLayer,
        form,
        tableInterface,
        destroyDialog: (dlgId) => {
            // нужно через timeout так как после вызова destroyDialog следуют обращения к state
            setTimeout(() => { setTopLayer([...topLayer.filter(c => c.props.id != dlgId)]) }, 100)
        }
    }));
*/

    const setFilters = React.useCallback((config) => {
        tableInterface.requestParams.filters = config;
        tableInterface.refreshData();
    }, [tableInterface])


    const callForm = React.useCallback((id) => {
        editorContext.id = id;
        setFormVisible(true);
    }, [editorContext])
    // тут формируются кнопки
    const buttons = [
        <PanelButton title="Добавить" key="add" onClick={() => callForm()} icon={<PlusOutlined />} />,
        <PanelButton title="Обновить" key="refresh" onClick={() => tableInterface.refreshData()} icon={<RedoOutlined />} />     
    ];
/*
    if (menuCommand) {
         buttons.push(<Dropdown.Button key="more"
             className="more-dropbutton"
             trigger="click"
             overlay={menuCommand} icon={<MoreOutlined />} />);
    }
*/
    if(isMobile()) {
        const filters = buildFilters();
        buttons.push(<FilterButton key="filter" filters={filters} 
                        onChange={(fc) => setFilters(fc)} 
                        initValues={initFilters}/>);
    }

    const afterEdit = React.useCallback((values) => {
        const flatRecord =  values.data.record;
        transformerRecord(flatRecord);        
        tableInterface.updateRecord(flatRecord);
        setUpdateRecords([...updateRecords, flatRecord])
    }, [tableInterface, updateRecords])
    const afterAdd = React.useCallback((values) => {
        const flatRecord =  values.data.record;
        transformerRecord(flatRecord);        
        tableInterface.insFirstRecord(flatRecord);
        setUpdateRecords([...updateRecords, flatRecord])
        }, [tableInterface, updateRecords])

    // меню для записи
    const recordMenu = (config, record) => (
        <React.Fragment>
            <Menu.Item key="detachDevice" icon={<ApiOutlined />}
                onClick={(ev) => {
                    ev.domEvent.stopPropagation(); // чтобы предовратить запуск окна редактирования
    
                    if (!config['owner.login']) {
                        notification.error({
                            message: `У прибора ${config.number} нет владельца`
                        })
                        return;
                    }
    
                    confirm(`Вы уверены, что хотите отвязать владельца ${config['owner.login']} от прибора ${config.number}`, () => {
                        let valuesForSend = { id: config.id };
                        let promise = requestToAPI.post(URI_FOR_DETACH_DEVICE, valuesForSend)
                        promise
                            .then(response => {
                                // финализация выполнения
                                tableInterface.refreshData();
                                notification.success({
                                    message: "Отвязывание прибора выполнено успешно"
                                })
                            })
                            .catch(error => {
                                notification.error({
                                    message: "Ошибка отвязывания прибора",
                                    description: error.message
                                })
                            })
                    })
                }}>
                Отвязать прибор
            </Menu.Item>
        </React.Fragment>
    )

    return (
        <App subsystem={MNU_SUBSYSTEM} menu={MNU_MENU} submenu={MNU_SUMMENU}
            breadcrumb={[{ label: NAME_MENU },{ label: ENTITY_TITLE }]}
            afterLogin={forceUpdate}
            buttons={buttons}
            helpId={MODE_HELP_ID}>
            
            <ModuleHeader
                title={ENTITY_TITLE}
                searchValue={tableInterface.requestParams?tableInterface.requestParams.search:undefined}
                onSearch={value => {
                    tableInterface.requestParams.search = value;
                    tableInterface.refreshData();
                }}
                buttons={buttons}
            />
            <FilterPanelExt onChange={(fc) => setFilters(fc)} initValues={initFilters}>
                {buildFilters()}
            </FilterPanelExt>
            <DataTable className="mod-main-table"
                uri={{
                    forSelect: URI_FOR_GET_LIST,
                    forDelete: URI_FOR_DELETE
                }}
                columns={COLUMNS}
                exportNameFile='devices'
                autoRefresh={AUTO_REFRESH}
                editCallBack={(record) => callForm(record.id)}
                interface={tableInterface}
                onSelectedChange={() => forceUpdate()}
                onAfterRefresh={() => setUpdateRecords([])}
                updateRecords={updateRecords}
                transformerData={transformerData}
                quickFilterResetButtonVisible={true}
                recordMenu={recordMenu}
            />
            <EditForm
                interface={tableInterface}
                id={EDIT_FORM_ID}
                copyButtonFlag={true}
                visible={formVisible}
                form={form}
                width={FORM_WIDTH}
                editorContext={editorContext}
                afterSave={(response) => {
                    setFormVisible(false);
                    if (response) {
                        if (!editorContext.id) {
                            afterAdd(response)
                        } else {
                            afterEdit(response)
                        }
                    }
                }}
                transformerData={transformerRecord}
                afterCopy={afterAdd}
                afterCancel={() => {
                    setFormVisible(false);
                }}
                idName="id">
                {buildForm(form)}
            </EditForm>
            {topLayer.map(item => item)}
        </App>
    )
}
export default withRouter(Device);