import React from 'react';
import { Form, Input } from 'antd';

const { TextArea } = Input;

const TemplateNotificationForm = (props) => {
    const firstInputRef = React.useRef(null);

    React.useEffect(() => {
        setTimeout(() => {
            if (firstInputRef.current) {
                firstInputRef.current.focus({
                    cursor: 'end',
                })
            }
        }, 100);
    });

    return <Form
        form={props.form}
        layout="vertical"
        name="templatemessage-frm"
        onFieldsChange={props.onFieldsChange}
        initialValues={props.initialValues}>
        <Form.Item
            label="Код"
            name='code'
            rules={[
                { required: true },
                { max: 50 }
            ]}>
            <Input ref={firstInputRef}/>
        </Form.Item>
        <Form.Item
            label="Наименование"
            name='title'
            rules={[
                { required: true },
                { max: 50 }
            ]}>
            <Input />
        </Form.Item>
        <Form.Item
           label="Текст"
            name='text'
            rules={[
                    { required: true},
                    {max: 500 }
            ]}>
            <TextArea style={{ height: "200px" }} />
        </Form.Item>
    </Form>
}

export default TemplateNotificationForm;