// Контуры (в lowerCase)
export const CONTOUR_ADMIN = { name: "admin", title: "Администрирование" };
export const CONTOUR_REFBOOKS = { name: "refbooks", title: "НСИ" };
export const CONTOUR_DOCUMENTS = { name: "documents", title: "Документы" };

export const CONTOURS = {
    "refbooks": "НСИ",
    "documents": "Документы",
    "admin": "Администрирование",
}

// Модули (в lowerCase)
// В контуре "Администрирование"
export const MODULE_CREDENTIAL = { name: "credential", title: "СПРАВОЧНИКИ" };
export const MODULE_TYPEOFUSERS = { name: "typesOfUsers", title: "ТИПЫ ПОЛЬЗОВАТЕЛЕЙ" };
export const MODULE_AUDIT = { name: "audit", title: "АУДИТ" };
export const MODULE_RESOURCE = { name: "resource", title: "ДОКУМЕНТЫ" };
export const MODULE_NOTIFICATION = { name: "admin", title: "УПРАВЛЕНИЕ ОПОВЕЩЕНИЯМИ" };
export const MODULE_TYPEDEVICES = { name: "typedevices", title: "ТИПЫ ПРИБОРОВ" }
export const MODULE_DEVICE = { name: "device", title: "ПРИБОРЫ" }
// В контуре "НСИ"
export const MODULE_EDIZM = { name: "edizm", title: "Единицы измерения" };
export const MODULE_CAPCLASS = { name: "capclass", title: "Справочники" };
// В контуре "Тестовый контур"
export const MODULE_REQUEST = { name: "request", title: "Заявки" };

export const NONE = {};

export let MODULES = {};
MODULES[MODULE_CREDENTIAL.name] = MODULE_CREDENTIAL.title;
MODULES[MODULE_TYPEOFUSERS.name] = MODULE_TYPEOFUSERS.title;
MODULES[MODULE_AUDIT.name] = MODULE_AUDIT.title;
MODULES[MODULE_RESOURCE.name] = MODULE_RESOURCE.title;
MODULES[MODULE_NOTIFICATION.name] = MODULE_NOTIFICATION.title;
MODULES[MODULE_EDIZM.name] = MODULE_EDIZM.title;
MODULES[MODULE_CAPCLASS.name] = MODULE_CAPCLASS.title;
MODULES[MODULE_REQUEST.name] = MODULE_REQUEST.title;
MODULES[MODULE_TYPEDEVICES.name] = MODULE_TYPEDEVICES.title;
MODULES[MODULE_DEVICE.name] = MODULE_DEVICE.title;

export const CONTOURS_WITH_MODULES = new Map([
    // [CONTOUR_REFBOOKS, [MODULE_EDIZM, MODULE_CAPCLASS]],
    // [CONTOUR_DOCUMENTS, [MODULE_REQUEST]],
    [CONTOUR_ADMIN, [MODULE_CREDENTIAL, [MODULE_TYPEOFUSERS, MODULE_TYPEDEVICES, MODULE_DEVICE], MODULE_AUDIT, MODULE_RESOURCE, MODULE_NOTIFICATION]],
]);