import React from 'react';
import {Form} from 'antd';
import DataTable from "../../lib/DataTable";
import App from '../../App';
import ModuleHeader from "../../lib/ModuleHeader";
import { FilterPanelExt } from "../../lib/FilterPanelExt";
import { FilterButton } from '../../lib/FilterButton';
import { withRouter } from "react-router";
import { DEFAULT_TABLE_CONTEXT } from "../../lib/Const";
import EditForm from "../../lib/EditForm";
import TemplateNotificationForm from "./TemplateNotificationForm";
import { CONTOUR_ADMIN, MODULE_NOTIFICATION } from "../../lib/ModuleConst"
import { responsiveMobileColumn, isMobile } from '../../lib/Responsive';
import {RedoOutlined} from '@ant-design/icons';
import { MUT_INS_MESSAGE, MUT_UPD_MESSAGE, QUERY_GET_LIST_MESSAGE, QUERY_GET_MESSAGE,MUT_DEL_MESSAGE } from '../../lib/Query';
import { PanelButton } from '../../lib/PanelButton';

const MOD_TITLE = "Управление оповещениями";
const MODE_HELP_ID = "/help/templatenotification";
const CONTOUR = CONTOUR_ADMIN;
const MODULE = MODULE_NOTIFICATION;

// URI для использования формой со списком (текущей) и формой добавления/изменения
const URI_FOR_GET_LIST = QUERY_GET_LIST_MESSAGE;
const URI_FOR_GET_ONE = QUERY_GET_MESSAGE;
const URI_INS_MESSAGE = MUT_INS_MESSAGE;
const URI_FOR_UPDATE = MUT_UPD_MESSAGE;
const URI_FOR_DELETE = MUT_DEL_MESSAGE;

// позиция в меню
// в subsystem - key верхнего меню
const MNU_SUBSYSTEM = CONTOUR.name;
// в menu - key бокового главного
const MNU_MENU = MODULE.name;
//const NAME_MENU = MODULE.title;
// в submenu - key бокового подменю (финальный пункт)
// его имя равно имени модуля
const MNU_SUMMENU = MODULE.name;
// автоматическое обновление при монтировании компонента
const AUTO_REFRESH = true;

// колонки в таблице
const COLUMNS = [
    {
        title: 'Код',
        dataIndex: 'code',
        sorter: true,
        width: "280px",
        responsive: responsiveMobileColumn(),
    },
    {
        title: 'Наименование',
        dataIndex: 'title',
        sorter: true,
        ellipsis: true,
        defaultSortOrder: 'ascend',
    }
]

// преобразование пакета данных в плоскую структуру
const transformerRecord = (r)=>{
}    

const transformerData = (data)=>{
    data.forEach(r => 
        transformerRecord(r)
    );
    return data;
}

// Уникальный идентификатор формы редактировавания
const EDIT_FORM_ID = "templatemessage-frm";
// Форма для редактирования
const buildForm = (form) => {
    return <TemplateNotificationForm form={form} initialValues={{}} />
}
// размер формы, -1 - по умолчанию, FORM_MAX_WIDTH - максимальная ширина
const FORM_WIDTH = -1;

// Создание компонент для фильтров
// key это уникальное имя фильтра, попадает в REST API
const buildFilters = () => {
    return <React.Fragment>

    </React.Fragment>
}
// начальное значение фильтров
// если значение фильра не объект, а простое значение,
// то значение имени свойства компонента принимается как defaultValue компонента
const initFilters = {
}

// дополнительные команды
// если меню нет, то и кнопки нет
/* 
const buildMenuCommand = (config, handleMenuClick) => {
    return <Menu onClick={handleMenuClick}>
        {buildPrintMenu(MODULE.name, config)}
    </Menu>
};

// обрабочик меню
const buildMenuHandler = (config) => {
    return (ev) => {
        console.log('click', ev);
    }
}
*/

// меню для записи
const recordMenu =  undefined;
/*
 = (config, record) => (
    <React.Fragment>
        {buildEntityPrintMenu(ENTITY, record, config)}
    </React.Fragment>
)
*/

//===============================================================================
// Основной функциональный компонент
//===============================================================================
/**
 * Таблица передает на сервер post-запрос в теле которого
 * pagination - информация о странице
 * sort - сортировка
 * filters - фильтры (+ быстрые фильтры начинаются с quick.*)
 * search - строка полнотекстового поиска
 */
const TemplateNotification = (props) => {
    let [formVisible, setFormVisible] = React.useState(false);
    const [topLayer] = React.useState([]);
    let [editorContext] = React.useState({
        uriForGetOne: URI_FOR_GET_ONE,
        uriForInsert: URI_INS_MESSAGE,
        uriForUpdate: URI_FOR_UPDATE
    });
    const [tableInterface] = React.useState(Object.assign({}, DEFAULT_TABLE_CONTEXT));
    const [form] = Form.useForm();
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);
    const [updateRecords, setUpdateRecords] = React.useState([]);

/*     
    const [menuCommand] = React.useState(buildMenuCommand(buildMenuHandler({
        'topLayer': topLayer,
        'setTopLayer': setTopLayer,
        'form': form,
        'tableInterface': tableInterface,
        'destroyDialog': (dlgId) => {
            // нужно через timeout так как после вызова destroyDialog следуют обращения к state
            setTimeout(() => { setTopLayer([...topLayer.filter(c => c.props.id != dlgId)]) }, 100)
        }
    })));
 */

    const setFilters = React.useCallback((config) => {
        tableInterface.requestParams.filters = config;
        tableInterface.refreshData();
    }, [tableInterface])


    const callForm = React.useCallback((id) => {
        editorContext.id = id;
        setFormVisible(true);
    }, [editorContext])

    // тут формируются кнопки
    const buttons = [
        <PanelButton title = "Обновить" key="refresh" onClick={() => tableInterface.refreshData()} icon={<RedoOutlined />} />
    ];
    /*
    if (menuCommand) {
        buttons.push(<Dropdown.Button key="more"
            className="more-dropbutton"
            trigger="click"
            overlay={menuCommand} icon={<MoreOutlined />} />);
    }
    */
    if (isMobile()) {
        const filters = buildFilters();
        buttons.push(<FilterButton key="filter" filters={filters}
            onChange={(fc) => setFilters(fc)}
            initValues={initFilters} />);
    }

    const afterEdit = React.useCallback((values) => {
        const flatRecord =  values.data.record;
        transformerRecord(flatRecord);        
        tableInterface.updateRecord(flatRecord);
        setUpdateRecords([...updateRecords, flatRecord])
    }, [tableInterface, updateRecords])
    const afterAdd = React.useCallback((values) => {
        const flatRecord =  values.data.record;
        transformerRecord(flatRecord);        
        tableInterface.insFirstRecord(flatRecord);
        setUpdateRecords([...updateRecords, flatRecord])
        }, [tableInterface, updateRecords])


    return (
        <App subsystem={MNU_SUBSYSTEM} menu={MNU_MENU} submenu={MNU_SUMMENU}
            breadcrumb={[{ label: MOD_TITLE }]}
            afterLogin={forceUpdate}
            buttons={buttons}
            helpId={MODE_HELP_ID}>
            <ModuleHeader
                title={MOD_TITLE}
                onSearch={value => {
                    tableInterface.requestParams.search = value;
                    tableInterface.refreshData();
                }}
                buttons={buttons}
            />
            <FilterPanelExt onChange={(fc) => setFilters(fc)} initValues={initFilters}>
                {buildFilters()}
            </FilterPanelExt>
            <DataTable className="mod-main-table"
                uri={{
                    forSelect: URI_FOR_GET_LIST,
                    forDelete: URI_FOR_DELETE
                }}
                columns={COLUMNS}
                exportNameFile='templateNotification'
                autoRefresh={AUTO_REFRESH}
                editCallBack={(record) => callForm(record.id)}
                interface={tableInterface}
                onSelectedChange={() => forceUpdate()}
                onAfterRefresh={() => setUpdateRecords([])}
                updateRecords={updateRecords}
                transformerData={transformerData}
                recordMenu={recordMenu}
                idName="id"
                quickFilterResetButtonVisible={true}
                />
            <EditForm
                id={EDIT_FORM_ID}
                copyButtonFlag={true}
                visible={formVisible}
                form={form}
                width={FORM_WIDTH}
                editorContext={editorContext}
                afterSave={(response) => {
                    console.log(this)
                    setFormVisible(false);
                    if (response) {
                        if (!editorContext.id) {
                            afterAdd(response)
                        } else {
                            afterEdit(response)
                        }
                    }
                }}
                afterCopy={afterAdd}
                afterCancel={() => {
                    setFormVisible(false);
                }}
                idName="id">
                {buildForm(form)}
            </EditForm>
            {topLayer.map(item => item)}
        </App>
    )
}

export default withRouter(TemplateNotification);