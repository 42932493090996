import React from 'react';
import { useHistory } from "react-router-dom";

import '../resources/css/App.css';

import 'antd/dist/antd.css';
import { Layout, Menu, Breadcrumb, Button, Popover, Drawer, Form } from 'antd';
import {
    UserOutlined, 
    HomeOutlined, LogoutOutlined,
    AuditOutlined,SettingOutlined,
    MenuOutlined, CloseOutlined,
    MailOutlined
} from '@ant-design/icons';
import { Link } from "react-router-dom";
import requestToAPI from "./lib/Request";
import { logout, restoreToken,setNewUserName } from "./lib/LoginForm";
import logo from '../resources/images/favicon.ico';
import logoFull from '../resources/images/logo-full.svg';
import {
    MODULE_CREDENTIAL,MODULE_TYPEOFUSERS, MODULE_AUDIT, MODULE_NOTIFICATION,
    CONTOURS_WITH_MODULES, MODULES, MODULE_TYPEDEVICES, MODULE_DEVICE
} from "./lib/ModuleConst";
import { buildMobileButtons } from "./lib/Utils";
import { ShowModal } from "./lib/EditForm";

import { DesktopOrTabletScreen, MobileScreen, setMobile } from './lib/Responsive'
import { useMediaQuery } from 'react-responsive'
import {MUT_SET_SYSUSER_PASSWORD,MUT_UPD_SYSUSER_WITHOUT_BLOCK,QUERY_GET_SYSUSER} from './lib/Query';
import {SettingsForm} from './modules/SettingsForm';
import { PanelButton } from './lib/PanelButton';

const { SubMenu } = Menu;
const { Content, Sider } = Layout;

// здесь левое меню, в зависимости от subsystem
const getSubMenu = (sys, clsMenuName) => {
    // Получим список всех модулей выбранного контура
    let allModules = [];
    CONTOURS_WITH_MODULES.forEach((value, key) => {
        if (key.name === sys) {
            allModules.push(...value);
        }
    });
    // Получим список всех доступных пользовтелю модулей
    //  const modules = (JSON.parse(localStorage.getItem("modules")) ?? []).map(value => value.applicationExe.toLowerCase());
    // Отфильтруем список модулей контура так, чтобы остались только доступные пользователю модули контура
    //  const allowModules = allModules.filter(value => modules.indexOf(value.name) !== -1);
    //const allowModules = allModules;
    const clsmmenu = clsMenuName || "main-menu";

    let menuItems = [];
    allModules.forEach(value => {
        switch (value.name) {
            case MODULE_CREDENTIAL.name:
                menuItems.push(
                    <SubMenu className={clsmmenu} key={MODULE_CREDENTIAL.name} icon={<UserOutlined />} title={MODULE_CREDENTIAL.title}>
                        <SubMenu key={MODULE_TYPEOFUSERS.name} title={MODULE_TYPEOFUSERS.title}>
                            <Menu.Item key={MODULE_TYPEOFUSERS.name + ".sm1"}>
                                <Link to="/proguser">ВЛАДЕЛЬЦЫ</Link>
                            </Menu.Item>
                            <Menu.Item key={MODULE_TYPEOFUSERS.name + ".sm2"}>
                                <Link to="/servicesusers">СЛУЖЕБНЫЕ ПОЛЬЗОВАТЕЛИ</Link>
                            </Menu.Item>
                        </SubMenu>
                        <Menu.Item key={MODULE_TYPEDEVICES.name + ".sm1"}>
                               <Link to="/typedevices">ТИПЫ ПРИБОРОВ</Link> 
                        </Menu.Item>
                        <Menu.Item key={MODULE_DEVICE.name}>
                               <Link to="/device">ПРИБОРЫ</Link> 
                        </Menu.Item>
                    </SubMenu>
                );
                break;
            case MODULE_AUDIT.name:
                menuItems.push(
                    <SubMenu className={clsmmenu} key={MODULE_AUDIT.name} icon={<AuditOutlined />} title={MODULE_AUDIT.title}>
                        <Menu.Item key={MODULE_AUDIT.name + ".sm1"}>
                            <Link to="/audit">ЖУРНАЛ ЛОГОВ</Link>
                        </Menu.Item>
                    </SubMenu>
                );
                break;
            case MODULE_NOTIFICATION.name:
                menuItems.push(
                    <Menu.Item className={clsmmenu} key={MODULE_NOTIFICATION.name} icon={<MailOutlined />}>
                       <Link className="link" to="/templatenotification">УПРАВЛЕНИЕ ОПОВЕЩЕНИЯМИ</Link>
                    </Menu.Item>
                );
                break;
            default:
        }
    });
    return menuItems;

}

let startFlag = false;

const App = (props) => {
    const [form] = Form.useForm();
    const [topLayer, setTopLayer] = React.useState([]);
    var [collapsed, setCollapse] = React.useState(props.defaultCollapsed);
    var [visibleUserPopover, setVisibleUserPopover] = React.useState(false);
    const [visibleMobileMenu, setVisibleMobileMenu] = React.useState(false);

    const history = useHistory();

    if (!startFlag) {
        restoreToken();
    }

    React.useEffect(() => {
        if (!startFlag) {
            if (!requestToAPI.authToken) {
                history.push("/");
            }
            startFlag = true;
        }
    });

    const changeSettings = () => {
         setVisibleUserPopover(false);
        const config = {
            form: form,
            dialogId:"modal-settings",
            editorContext: { 
                id: requestToAPI.user.id, 
                uriForUpdate: (values)=>{
                    const {newPassword,changePasswordFlag,confirmPassword,...changeUserValues} = values;
                    delete changeUserValues.login
                    let promises = [
                        requestToAPI.post(MUT_UPD_SYSUSER_WITHOUT_BLOCK,changeUserValues)
                            .catch(error=>{
                                console.error(error);
                               	throw error;
                            })
                    ];
                    if(changePasswordFlag) {
                        promises.push(
                            requestToAPI.post(MUT_SET_SYSUSER_PASSWORD,{id:values.id,newPassword:newPassword})
                            .catch(error=>{
                                console.error(error);
                               	throw error;
                            })
                        );    
                    }
                    return Promise.all(promises);
                },
                uriForGetOne:QUERY_GET_SYSUSER
            },
            destroyDialog: (dlgId) => {
                form.resetFields();
                setTopLayer([...topLayer.filter(c => c.props.id != dlgId)]);
            },
            transformerData:(r)=>{
                r["ident.blocked"]=r.ident.blocked;
                r["ident.sysUser.email"]=r.ident.sysUser.email;
                r["ident.sysUser.phone"]=r.ident.sysUser.phone;
                delete r.ident;
            }
        }
        // формируем диалог
        const dialog = ShowModal({
            ...config,
            title: "Настройки пользователя "+requestToAPI.user.login,
            content: <SettingsForm />,
            idName: "id",
            width: "520px",
            afterSave:(values)=>{
                const record = values[0].data.record;
                setNewUserName(record.firstName,record.lastName,record.patronymic);
            }
        });
        // вставляем Modal в top layer
        setTopLayer([dialog])
    }

    const logoutCB = React.useCallback(() => {
        setVisibleUserPopover(false);
        logout(history, props.afterLogout);
    }, [setVisibleUserPopover, history, props.afterLogout])

    const userTitle = React.useCallback(() => {
        if (!requestToAPI.user) {
            return undefined;
        }        
        return <div style={{ padding: 16 }}><UserOutlined />
            <span style={{ marginLeft: 8 }}>{requestToAPI.user.name}</span>
        </div>
    }, [])

    const userContent = React.useCallback(() => {
        return <Menu selectable={false}>
            {
                requestToAPI.authToken ? <>
                    <Menu.Item key="changeSettings" icon={<SettingOutlined />} onClick={changeSettings}>Настройки...</Menu.Item>
                    <Menu.Item key="logout" onClick={logoutCB} icon={<LogoutOutlined />}>Выход</Menu.Item>
                </> : undefined
            }
        </Menu>
        // eslint-disable-next-line
    }, [logoutCB])

    const isMobile = !useMediaQuery({ minWidth: 768 })
    setMobile(isMobile);

    const moduleName = MODULES[(typeof (props.menu) === "string" ? props.menu : props.menu[0])];
    document.title = moduleName ?? "E-MODE";

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <DesktopOrTabletScreen>
                <Sider collapsible collapsed={collapsed} onCollapse={setCollapse} width="300px">
                    <Link to="/"><div className="logo" style={{paddingLeft:10}}><img src={logoFull} alt="Logotype"/></div></Link>
                    <Link to="/"><img src={logo} className="logo-img" alt="Logo sign"></img></Link>
                    <Menu
                        className="main-menu"
                        mode="inline"
                        defaultOpenKeys={Array.isArray(props.menu) ? props.menu : [props.menu]}
                        defaultSelectedKeys={props.submenu !== "none" ? [props.submenu] : (Array.isArray(props.menu) ? props.menu : [props.menu])}
                    >
                        {getSubMenu(props.subsystem)}
                    </Menu>
                </Sider>
            </DesktopOrTabletScreen>
            <MobileScreen>
                <Drawer
                    className="drawer-main-menu"
                    title={<div><img src={logoFull} alt="Logotype"/>
                        <Button shape="circle" className="noborder align-right"
                            style={{paddingBottom:'10px'}}
                            icon={<CloseOutlined />}
                            onClick={() => setVisibleMobileMenu(false)} /></div>
                    }
                    placement={"left"}
                    closable={false}
                    visible={visibleMobileMenu}
                    onClose={() => setVisibleMobileMenu(false)}
                >
                    <Menu mode="inline" defaultOpenKeys={Array.isArray(props.menu) ? props.menu : [props.menu]} defaultSelectedKeys={props.submenu !== "none" ? [props.submenu] : (Array.isArray(props.menu) ? props.menu : [props.menu])}>
                        {getSubMenu(props.subsystem)}
                    </Menu>
                </Drawer>
            </MobileScreen>
            <Layout className="site-layout">
                <DesktopOrTabletScreen>
                    <Menu className="main-menu" mode="horizontal" defaultSelectedKeys={[props.subsystem]}>
                    </Menu>
                </DesktopOrTabletScreen>
                <MobileScreen>
                    <div className="mob-button-panel">
                        <PanelButton title="Меню" className="main-menu-drawer-open" style={{paddingTop:'5px'}}
                            icon={<MenuOutlined />}
                            onClick={() => setVisibleMobileMenu(true)} />
                        {buildMobileButtons(props.buttons)}
                    </div>
                </MobileScreen>
                <Popover visible={visibleUserPopover}
                    onVisibleChange={(value) => setVisibleUserPopover(value)}
                    placement="bottomRight"
                    title={userTitle()}
                    content={userContent()} trigger="click">
                        <PanelButton title="Профиль" className="user-dropdown-login" icon={<UserOutlined />} />
                </Popover>
                <Content style={{ margin: '0 16px' }}>
                    <Breadcrumb className="main-breadcrumb">
                        {props.breadcrumb ? <Breadcrumb.Item href="/"><HomeOutlined /></Breadcrumb.Item> : ''}
                        {props.breadcrumb ? props.breadcrumb.map((o, idx) => <Breadcrumb.Item {...(o.href ? { href: o.href } : {})} key={idx}>{o.label}</Breadcrumb.Item>) : ''}
                    </Breadcrumb>
                    <div className="site-layout-workspace">
                        {props.children}
                    </div>
                </Content>
            </Layout>
            {topLayer.map(item => item)}
        </Layout>
    );
}


export default App;
